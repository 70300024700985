<template>
  <el-dialog
    :title="$t('w_0179')"
    :visible.sync="addShow"
    center=""
    width="25%"
    :close-on-click-modal="false"
    :before-close="handleCloseBank"
  >
    <div class="add_pay">
      <el-form
        ref="form"
        :rules="rules"
        :model="form"
        label-position="right"
        label-width="100px"
      >
        <el-form-item :label="$t('MY_WAL_24') + ':'" prop="cardNumber">
          <el-input
            v-model="form.cardNumber"
            :placeholder="$t('w_0271')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('w_0299') + ':'" prop="pkBank">
          <el-select v-model="form.pkBank" :placeholder="$t('w_0270')">
            <el-option
              v-for="(item, index) in bankCardChioceList"
              :key="index"
              :label="item.bankName"
              :value="item.pkId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('CK_KS_43') + ':'" prop="subBankName">
          <el-input
            v-model="form.subBankName"
            :placeholder="$t('w_0272')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('PER_DA_14') + ':'" prop="accountName">
          <el-input
            v-model="form.accountName"
            :placeholder="$t('w_0300')"
          ></el-input>
        </el-form-item>
        <el-form-item :label="$t('MY_WAL_35')" prop="idType">
          <el-select v-model="form.idType" :placeholder="$t('w_0301')">
            <el-option
              v-for="(item, index) in cardTypeList"
              :key="index"
              :label="item.label"
              :value="item.value"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('MY_WAL_36') + ':'" prop="idCard">
          <el-input
            v-model="form.idCard"
            :placeholder="$t('w_0269')"
          ></el-input>
        </el-form-item>
        <el-form-item
          v-if="pkCountry == 1"
          :label="$t('w_0180') + ':'"
          prop="phone"
        >
          <el-input v-model="form.phone" :placeholder="$t('w_0184')"></el-input>
        </el-form-item>
        <!-- <el-form-item :label="$t('w_0181')+':'">
          <el-input
            v-model="form.validdate"
            :placeholder="$t('w_0185')"
          ></el-input>
        </el-form-item> -->
        <!-- <el-form-item :label="$t('w_0182')+':'">
          <el-input v-model="form.cvv2" :placeholder="$t('w_0186')"></el-input>
        </el-form-item> -->
        <el-form-item
          v-if="pkCountry == 1 && cancode"
          :label="$t('w_0043') + ':'"
          prop="verificationCode"
        >
          <el-input
            v-model="form.verificationCode"
            :placeholder="$t('w_0057')"
          ></el-input>
          <div class="hqyzm1" :class="isSend ? 'hui' : ''" @click="startTime">
            {{ getCode }}
          </div>
        </el-form-item>
      </el-form>
    </div>
    <div class="surebtn1">
      <div class="btn hh" @click="handleCloseBank">{{ $t("ENU_P_TYPE0") }}</div>
      <div class="btn" @click="submitForm('form')">{{ this.$t("w_0035") }}</div>
    </div>
  </el-dialog>
</template>
  
  <script>
import * as wal from "@/api/wallet.js";
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      cardTypeList: [], //证件类型
      addShow: false,
      form: {
        cardNumber: "",
        accountName: "",
        idCard: "",
        phone: "",
        pkBank: "",
        verificationCode: "",
        subBankName: "",
      },
      isSend: false,
      beginTime: 60,
      getCode: this.$t("w_0055"),
      timer: "",
      rules: {
        cardNumber: [
          { required: true, message: this.$t("w_0271"), trigger: "blur" },
        ],
        verificationCode: [
          { required: true, message: this.$t("w_0057"), trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (this.pkCountry === 1 && !value) {
                callback(new Error(this.$t("w_0057")));
              } else {
                callback();
              }
            },
          },
        ],
        idType: [
          { required: true, message: this.$t("w_0301"), trigger: "change" },
        ],
        accountName: [
          { required: true, message: this.$t("w_0300"), trigger: "blur" },
        ],
        subBankName: [
          { required: true, message: this.$t("w_0272"), trigger: "blur" },
        ],
        bankNo: [
          { required: true, message: this.$t("w_0188"), trigger: "blur" },
        ],
        name: [{ required: true, message: this.$t("w_0189"), trigger: "blur" }],
        idCard: [
          { required: true, message: this.$t("w_0269"), trigger: "blur" },
        ],
        // smsCode: [{ required: true, message: '请输入验证码', trigger: 'blur' }],
        phone: [
          {
            required: true,
            message: this.$t("w_0191"),
            trigger: "blur",
          },
          { min: 11, message: this.$t("CK_KS_73"), trigger: "blur" },
          {
            validator: (rule, value, callback) => {
              if (this.pkCountry === 1 && !value) {
                callback(new Error(this.$t("CK_KS_73")));
              } else {
                callback();
              }
            },
          },
        ],
        pkBank: [
          { required: true, message: this.$t("w_0302"), trigger: "change" },
        ],
      },
      ifpass: false,
      bankCardChioceList: [],
      pkCountry: "",
      cancode: true,
    };
  },
  props: {
    isAdd: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {
    isAdd(val) {
      this.addShow = val;
    },
  },
  created() {
    this.getBankCardChoiceList();
    this.checkIfWhite();
    this.pkCountry = this.userInfo.pkCountry;
  },

  methods: {
    checkIfWhite() {
      wal.checkIfWhite().then((res) => {
        if (res.code == 200 && res.flag == "N") {
          this.cancode = true;
        } else if (res.code == 200 && res.flag == "Y") {
          this.cancode = false;
        }
      });
    },
    getBankCardChoiceList() {
      wal.getBankCardChoiceList().then((res) => {
        this.bankCardChioceList = res.data;
      });
      //证件类型
      wal.getCardType().then((res) => {
        this.cardTypeList = res.data;
      });
    },
    handleCloseBank() {
      this.$emit("closeBind", 0);
      this.$refs.form.resetFields();
    },
    // 绑卡
    bindBank() {
      if (!this.form.verificationCode &&this.cancode&& this.pkCountry == 1) {
        this.$message({
          message: this.$t("w_0303"),
          type: "warning",
        });
        return;
      }
      if (!this.ifpass && this.pkCountry == 1) {
        this.$message({
          message: this.$t("w_0304"),
          type: "warning",
        });
        return;
      }
      let that = this;

      wal.bindWalletBankAdd(this.form).then((res) => {
        this.$message({
          message: this.$t("w_0305"),
          type: "success",
        });
        this.$refs.form.resetFields();
        setTimeout(() => {
          that.$emit("closeBind", 1);
        }, 1500);
      });
    },
    // 绑卡获取验证码
    submitForm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.pkCountry == 1) {
            if (this.cancode) {
              wal
                .verifyBankCard(this.form)
                .then((res) => {
                  if (res.code == 200) {
                    this.ifpass = true;
                    this.bindBank();
                  } else {
                    this.ifpass = false;
                    this.$message({
                      message: res.msg,
                      type: "warning",
                    });
                  }
                })
                .catch((err) => {
                  this.beginTime = 0;
                  this.ifpass = false;
                });
            } else {
              this.ifpass = true;
              this.bindBank();
            }
          } else {
            this.bindBank();
          }
        } else {
          // console.log('error submit!!')
          return false;
        }
      });
    },
    startTime() {
      if (!this.form.phone) {
        this.$message({
          message: this.$t("MY_WAL_47"),
          type: "warning",
        });
        return;
      } else {
        if (this.isSend) return;
        this.isSend = true;
        this.getCode = this.beginTime + this.$t("w_0058");
        this.timer = setInterval(() => {
          this.beginTime--;
          this.getCode = this.beginTime + this.$t("w_0058");
          if (this.beginTime < 0) {
            clearInterval(this.timer);
            this.getCode = this.$t("w_0055");
            this.beginTime = 60;
            this.isSend = false;
          }
        }, 1000);
        wal.getVerification({ phone: this.form.phone }).then((res) => {
          this.$message({
            message: this.$t("MY_WAL_49"),
            type: "success",
          });
        });
      }
    },
  },
};
</script>
  
  <style lang="scss" scoped>
.add_pay {
  padding: 20px;
  ::v-deep .el-form-item {
    border-bottom: 1px solid rgba(0, 0, 0, 0.05); // padding-bottom: 4px;
    margin-bottom: 20px;
  }
}
.hqyzm1 {
  background: #d5251d;
  border-radius: 8px;
  padding: 0 7px;
  color: #fff;
  font-size: 10px;
  cursor: pointer;
}
.hui {
  opacity: 0.5;
}
.add_pay {
  ::v-deep .el-form-item {
    display: flex;
    align-items: center;
  }
  ::v-deep .el-form-item__content {
    flex: 1;
    display: flex;
    margin-left: 0 !important;
  }
  ::v-deep .el-input {
    flex: 1;
    width: auto;
  }
  ::v-deep .el-input__inner {
    border: 0;
  }
}
.surebtn1 {
  display: flex;
  align-items: center;
  justify-content: space-around;
  .btn {
    width: 180px;
    height: 50px;
    background: #d5251d;
    border-radius: 6px 6px 6px 6px;
    text-align: center;
    font-size: 14px;
    font-family: PingFang SC-Medium, PingFang SC;
    font-weight: 500;
    color: #ffffff;
    line-height: 50px;
    cursor: pointer;
  }

  .hh {
    background: #cccccc;
  }
}
::v-deep .el-dialog__title {
  font-weight: 500;
}
</style>