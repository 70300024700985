<!--
 * @Descripttion: 
 * @version: 
 * @Author: 王三华
 * @Date: 2023-11-25 10:37:04
-->
<template>
  <div class="contain">
    <div class="kuang">
      <div class="tit1">{{ $t('MY_WAL_32') }}</div>
      <div class="img_cen"
           v-if="hasRegin">
        <div class="status-img">
          <img src="@/assets/images/markerSpace/status3.png" />
        </div>
        <div class="status-size bolds">{{$t('ENU_YES_NO_A_0')}}</div>
      </div>
      <el-form :model="smform"
               v-else
               :rules="smrules"
               label-width="auto"
               ref="smform">
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('MY_WAL_39')"
                          prop="idFront">
              <ImageUpload style="width:300px;height:165px"
                           :backimg="require('@/assets/images/markerSpace/idCard-img1.png')"
                           v-model="smform.idFront" />
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('MY_WAL_40')"
                          prop="idBack">
              <ImageUpload style="width:300px;height:165px"
                           :backimg="require('@/assets/images/markerSpace/idCard-img2.png')"
                           v-model="smform.idBack" />
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('MY_WAL_33')"
                          prop="accountName">
              <el-input disabled
                        v-model="smform.accountName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item class="sexclass"
                          :label="$t('MY_WAL_34')"
                          prop="sex">
              <el-radio-group v-model="smform.sex">
                <el-radio disabled
                          :label="item.value"
                          v-for="item in sexList"
                          :key="item.value">{{ item.label }}</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('MY_WAL_35')"
                          prop="idType">
              <el-select v-model="smform.idType"
                         disabled
                         :placeholder="$t('CK_KS_38')">
                <el-option v-for="(item, index) in cardTypeList"
                           :key="index"
                           :label="item.label"
                           :value="item.value"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('MY_WAL_36')"
                          prop="idCard">
              <el-input disabled
                        v-model="smform.idCard"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('CK_KS_41')"
                          prop="pkBank">
              <el-select v-model="smform.pkBank"
                         :placeholder="$t('CK_KS_38')">
                <el-option v-for="(item, index) in bankCardChioceList"
                           :key="index"
                           :label="item.bankName"
                           :value="item.pkId"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('MY_WAL_24')"
                          prop="cardNumber">
              <el-input v-model="smform.cardNumber"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('CK_KS_43')"
                          prop="subBankName">
              <el-input v-model="smform.subBankName"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('MY_WAL_37')"
                          prop="theaddress">
              <el-cascader class="area"
                           v-model="smform.theaddress"
                           @change="setAddress"
                           :options="areaData"
                           :props="regionParams"
                           :placeholder="$t('CK_KS_38')"></el-cascader>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-form-item :label="$t('S_C_27')"
                        prop="address">
            <el-input v-model="smform.address"></el-input>
          </el-form-item>
        </el-row>
        <el-row>
          <el-col :span="12">
            <el-form-item :label="$t('w_0052')"
                          prop="phone">
              <el-input v-model="smform.phone"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item :label="$t('w_0043')"
                          prop="verificationCode">
              <el-input v-model="smform.verificationCode"
                        :placeholder="$t('S_C_70')">
                <span class="hqyzm1"
                      slot="suffix"
                      :class="isSend ? 'hui' : ''"
                      @click="startTime">{{ getCode }}</span>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <div class="agreementBox flex_ac">
          <div class="agreementIcon">
            <el-checkbox v-model="toShow"
                         @change="toCheck">
            </el-checkbox>
            <div class="agreementText flex">
              <span>
                <span>{{ $t('APP_ADD_19') }}</span>
                <span class="agreementDetail"
                      @click="openPop">《推广人员服务协议》</span>
              </span>
            </div>
          </div>
        </div>
        <div style="text-align: center">
          <el-button @click="saveData"
                     style="width: 117px; font-size: 16px; background-color: rgba(214, 24, 32, 1);color:#fff">{{ $t("MY_WAL_23") }}</el-button>
        </div>
      </el-form>
    </div>
    <el-dialog class="pop"
               width="70%"
               :append-to-body="true"
               :visible.sync="popupShow">
      <div class="pop_box"
           ref="scrollContainer">
        <img src="@/assets/images/tgxy.jpg"
             alt="">
        <span class="dialog-footer">
          <el-button type="primary"
                     @click="popupShow = false,hasRead = true">{{ $t('APP_ADD_19') }}</el-button>
        </span>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import * as wal from '@/api/wallet.js'
import * as api from '@/api/register'
import ImageUpload from '@/components/ImageUpload/index.vue'

export default {
  components: { ImageUpload },
  data() {
    return {
      toShow: false,
      popupShow: false,
      smform: {
        idType: 1,
        sex: 1,
      },
      smrules: {
        accountName: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        sex: [
          { required: true, message: this.$t('CK_KS_38'), trigger: 'change' },
        ],
        idType: [
          { required: true, message: this.$t('CK_KS_38'), trigger: 'change' },
        ],
        idCard: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        pkBank: [
          { required: true, message: this.$t('CK_KS_38'), trigger: 'change' },
        ],
        cardNumber: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        subBankName: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        theaddress: [
          { required: true, message: this.$t('CK_KS_38'), trigger: 'change' },
        ],
        address: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        phone: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        verificationCode: [
          { required: true, message: this.$t('S_C_70'), trigger: 'blur' },
        ],
        idFront: [
          { required: true, message: this.$t('MY_WAL_42'), trigger: 'change' },
        ],
        idBack: [
          { required: true, message: this.$t('MY_WAL_43'), trigger: 'change' },
        ],
      },
      sexList: [
        { label: this.$t('MY_WAL_44'), value: 1 },
        { label: this.$t('MY_WAL_45'), value: 2 },
      ],
      cardTypeList: [],
      bankCardChioceList: [],
      areaData: [],
      regionParams: {
        label: 'name',
        value: 'id',
        children: 'children',
        expandTrigger: 'hover',
      },
      ifupYy: false,
      isSend: false,
      beginTime: 60,
      getCode: this.$t('w_0055'),
      hasRegin: false,
      hasRead: false,
    }
  },
  watch: {
    'smform.idFront'(n, o) {
      if (n) {
        this.getIdCardInfo(n)
      }
    },
    'smform.idBack'(n, o) {
      if (n) {
        this.idBackChange(n)
      }
    },
  },
  mounted() {
    this.getDetail()
    this.getData()
  },
  methods: {
    openPop() {
      this.popupShow = true
      // setTimeout(() => {
      //   // 监听滚动条
      //   const scrollContainer = this.$refs.scrollContainer
      //   scrollContainer.addEventListener('scroll', this.handleScroll)
      // }, 100)
    },
    // handleScroll() {
    //   const scrollContainer = this.$refs.scrollContainer
    //   const scrollHeight = scrollContainer.scrollHeight
    //   const clientHeight = scrollContainer.clientHeight
    //   const scrollTop = scrollContainer.scrollTop
    //   if (scrollHeight - clientHeight === scrollTop) {
    //     // 滚动条已经到底部
    //     this.hasRead = true

    //   } else {
    //     // 滚动条未到底部
    //   }
    // },

    toCheck(e) {
      if (this.hasRead == true) {
        this.toShow = true
      } else {
        this.toShow = false
        this.$message({
          message: '请阅读推广人员服务协议',
          type: 'warning',
        })
      }
    },
    getIdCardInfo(n) {
      api
        .ocrIdCard({
          idCardUrl: n,
        })
        .then((res) => {
          if (res.code == 200) {
            this.$set(this.smform, 'accountName', res.data.name)
            this.$set(this.smform, 'idCard', res.data.idCard)
            this.$set(this.smform, 'sex', res.data.sex)
          }
        }).catch((err) => {
          this.$set(this.smform, 'accountName', '')
            this.$set(this.smform, 'idCard', '')
            this.$set(this.smform, 'sex', '')
          this.$set(this.smform, 'idFront', '')
        })
    },
    idBackChange(e) {
      if (e) {
        api
          .ocrIdCardBack({
            idCardBackUrl: e,
          })
          .then((res) => {
           
          }).catch((err) => {
            
          this.$set(this.smform, 'idBack', '')
        })
      }
    },
    getDetail() {
      api.authenticationDetails().then((res) => {
        if (res.data) {
          this.hasRegin = true
        } else {
          this.hasRegin = false
        }
      })
    },
    getData() {
      wal.getCardType().then((res) => {
        this.cardTypeList = res.data
      })
      wal.getBankCardChoiceList().then((res) => {
        this.bankCardChioceList = res.data
      })
      api.allArea().then((res) => {
        this.areaData = res.data
      })
    },
    setAddress(res) {
      this.smform.pkProvince = res[0]
      this.smform.pkCity = res[1]
      this.smform.pkCounty = res[2]
    },
    startTime() {
      if (!this.smform.phone) {
        this.$message({
          message: this.$t('MY_WAL_47'),
          type: 'warning',
        })
        return
      } else {
        if (this.isSend) return
        this.isSend = true
        this.getCode = this.beginTime + this.$t('w_0058')
        this.timer = setInterval(() => {
          this.beginTime--
          this.getCode = this.beginTime + this.$t('w_0058')
          if (this.beginTime < 0) {
            clearInterval(this.timer)
            this.getCode = this.$t('w_0055')
            this.beginTime = 60
            this.isSend = false
          }
        }, 1000)
        wal.getVerification({ phone: this.smform.phone }).then((res) => {
          this.$message({
            message: this.$t('MY_WAL_49'),
            type: 'success',
          })
        })
      }
    },
    saveData() {
      this.$refs['smform'].validate((valid) => {
        if (valid) {
          if (this.toShow == false) {
            this.$message({
              message: '请阅读推广人员服务协议',
              type: 'warning',
            })
          } else {
            wal
              .addAuthentication(this.smform)
              .then((res) => {
                if (res.code == 200) {
                  this.$message({
                    message: res.msg,
                    type: 'success',
                  })
                  this.getDetail()
                }
              })
              .catch((err) => {
                this.beginTime = 0
              })
          }
        } else {
          return false
        }
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.contain {
  padding-top: 20px;
  .kuang {
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    // padding-top: 20px;
    padding: 20px 300px;
    .tit1 {
      font-size: 34px;
      text-align: center;
      font-weight: 600;
      margin: 30px 0;
    }
    .hqyzm1 {
      color: #d5251d;
      cursor: pointer;
    }
    .hui {
      opacity: 0.5;
    }
  }
}
::v-deep .el-upload--picture-card {
  border: 0;
}
::v-deep .el-select,
.el-cascader {
  width: 100%;
}
.img_cen {
  padding: 100px 0;
  margin: 0 auto;
  text-align: center;
}
.status-img img {
  width: 80px;
  height: 80px;
}
.agreementBox {
  .agreementIcon {
    display: flex;
    margin-left: 10px;
  }
  .agreementText {
    margin-left: 10px;
    font-size: 14px;
    max-width: 600px;
    display: flex;
    flex-wrap: wrap;
    .agreementDetail {
      color: #72deed;
      font-weight: bold;
      font-size: 14px;
      cursor: pointer;
    }
  }
}
.pop {
  z-index: 20000 !important;
  text-align: left;
  .pop_box {
    max-height: 65vh;
    overflow-y: scroll;
    padding: 0rem 0.4rem 0.8rem 0.4rem;
    text-align: center;
    img {
      width: 100%;
      margin-bottom: 20px;
    }
  }

  .pop_title {
    width: 100%;
    height: 1.4rem;
    line-height: 1.4rem;
    font-size: 16px;
    text-align: center;
  }
  .pop_title1 {
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 500;
  }
  .pop_title2 {
    width: 100%;
    font-size: 16px;
    text-align: center;
    margin-top: 20px;
    font-weight: 500;
  }
}
</style>