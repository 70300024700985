<template>
  <div class="contain">
    <div class="kuang" v-if="isTab == 1">
      <el-form ref="queryParams" :model="queryParams" label-width="80px">
        <el-row>
          <el-col :span="6">
            <el-form-item :label="$t('CK_KS_51')">
              <el-date-picker
                v-model="creationTime"
                @change="changeTime"
                value-format="yyyy-MM-dd"
                type="daterange"
                range-separator="——"
                :start-placeholder="$t('CK_KS_4')"
                :end-placeholder="$t('CK_KS_5')"
              >
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="4" style="margin-left: 20px">
            <el-button type="info" @click="reset">{{$t('CK_KS_6')}}</el-button>
            <el-button type="danger" @click="getDataList">{{ $t('N_I_183') }}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- table 可申请订单 -->
    <div class="table">
      <div class="table_top">{{ $t('MY_CK_17') }}</div>
      <!-- 可申请订单 -->
      <template>
        <div class="table_tit">
          <div class="t_btm_l">
            <div class="tab_i1">{{ $t('w_0077') }}</div>
          </div>
          <div class="t_btm_r table_width">
            <div>{{ $t('MY_ORD_1') }}</div>
            <div>{{ $t('MY_ORD_2') }}</div>
            <div>{{ $t('MY_ORD_9') }}</div>
            <div>{{ $t('MY_CK_7') }}</div>
            <!-- <div>产品名称</div>
            <div>产品数量</div>
            <div>产品规格</div> -->
            
            <div>{{ $t('N_I_124') }}</div>
            <div>{{ $t('CK_KS_14') }}</div>
            
            <div>{{ $t('MY_ORD_10') }}</div>
            <div>{{ $t('w_0052') }}</div>
            <div>{{ $t('MY_ORD_11') }}</div>
            <div>{{ $t('MY_ORD_12') }}</div>
            <div>{{ $t('MY_ORD_13') }}</div>
            <div>{{$t('S_C_27')}}</div>
            <div>{{ $t('MY_ORD_14') }}</div>
          </div>
        </div>
        <div v-for="(item, index) in orderLists" class="order_i" :key="index">
          <!-- <div class="t_top">
            <span>订单编号：{{ item.orderCode }}</span>
            <span>下单时间：{{ item.creationTime }}</span>
            <span>{{$t('MY_ORD_3')}}：{{ item.payTime }}</span>
          </div> -->
          <div class="t_btm_a">
            <div class="t_btm_l">
              <div
                v-for="(ctem, cndex) in item.waresOrderList"
                :key="cndex"
                class="t_btm"
              >
                <!-- 详情 -->
                <div class="tab_i1">
                  <img :src="ctem.cover1" alt="" />
                  <div class="tab_i1_1">
                    <div class="prot">{{ ctem.waresName }}</div>
                    <div class="prot_i">
                      <div>{{ ctem.price }}</div>
                      <div>X{{ ctem.quantity }}</div>
                    </div>
                  </div>
                </div>
                <!-- 产品 -->
                <div
                  class="cp_i1"
                  v-for="(titem, tindex) in ctem.productList"
                  :key="tindex"
                >
                  <img :src="titem.cover" alt="" />
                  <div class="cp_i1_1">{{ titem.productName }}</div>
                  <div class="cp_i1_2">x{{ titem.quantity }}</div>
                </div>
              </div>
            </div>
            <div class="t_btm_r table_width">
              <!-- 订单编号 -->
              <div>
                {{ item.orderCode }}
              </div>
              <!-- 类型 -->
              <div>{{ item.orderTypeVal }}</div>
               <!-- 状态 -->
               <div v-if="item.orderStatus == '1'" style="color: #009b58">
                {{ item.orderStatusVal }}
              </div>
              <div v-if="item.orderStatus == '0'" style="color: #d61820">
                {{ item.orderStatusVal }}
              </div>
              <div v-if="item.orderStatus == '3'" style="color: #ffad41">
                {{ item.orderStatusVal }}
              </div>
              <div v-if="item.orderStatus == '5'" style="color: #ffad41">
                {{ item.orderStatusVal }}
              </div>
              <!-- <div>   {{ item.approveStatusVal }}</div> -->
             
            <div v-if="item.approveStatus == 3">
              <el-popover placement="right"
                          width="400"
                          trigger="hover">
                <div v-html="item.rejectMsg"></div>
                <div style="color:#b42b2a;cursor: pointer;" slot="reference">{{ item.approveStatusVal }}</div>
              </el-popover>
            </div>
            <div v-else>{{ item.approveStatusVal }}</div>
              <!-- 名称 -->
              <!-- <div>{{ item.orderTypeVal }}</div> -->
              <!-- 数量 -->
              <!-- <div>{{ item.orderTypeVal }}</div> -->
              <!-- 规格 -->
              <!-- <div>{{ item.orderTypeVal }}</div> -->
              <!-- pv -->
              <!-- <div>{{ item.orderAchieve }}</div> -->
              <!-- BV -->
              <!-- <div>{{ item.assAchievement }}</div> -->

              <!-- 编号 -->
              <div>{{ item.memberCode }}</div>
              <div>{{ item.memberName }}</div>
              <!-- 名 -->
              <div>{{ item.recName }}</div>
              <!-- 电话 -->
              <div>{{ item.recPhone }}</div>
              <!-- 地址 -->
              <div>{{ item.recProvinceVal }}</div>
              <div>{{ item.recCityVal }}</div>
              <div>{{ item.recCountyVal }}</div>
              <div>{{ item.recAddress }}</div>
              <!-- 操作 -->
              <div class="del">
                <el-popover
                  placement="top-start"
                  title=""
                  width="200"
                  trigger="hover"
                  :content="item.remark"
                >
                  <div slot="reference" style="cursor: pointer">{{$t('MY_WAL_22')}}</div>
                </el-popover>
                <div v-if="item.selfCode" class="del1" style="color:#0dba62" @click="clickFind(item)">{{$t('MY_CK_18')}}</div>
                <div v-if="!item.approveStatus" class="del1" @click="pickOrder(item)">{{$t('MY_CK_19')}}</div>
               
              </div>
            </div>
          </div>
        </div>
      </template>

      <!-- <template v-if="isTab == 1">
        <div class="table_tit">
          <div class="t_btm_l">
            <div class="tab_i1">订单详情</div>
          </div>
          <div class="t_btm_r table_width">
            <div>自提编码</div>
            <div>审核状态</div>
            <div>申请时间</div>
            <div>备注</div>
          
          </div>
        </div>
        <div v-for="(item, index) in selfLists" class="order_i" :key="index">
          <div class="t_top">
            <span>订单编号：{{ item.orderCode }}</span>
            <span>下单时间：{{ item.creationTime }}</span>
            <span>{{$t('MY_ORD_3')}}：{{ item.payTime }}</span>
          </div>
          <div class="t_btm_a">
            <div class="t_btm_l">
              <div
                v-for="(ctem, cndex) in item.saOrderSelfItemsLogExtList"
                :key="cndex"
                class="t_btm"
              >
          
                <div class="tab_i1">
                  <img :src="ctem.cover" alt="" />
                  <div class="tab_i1_1">
                    <div class="prot">{{ ctem.productName }}</div>
                    <div class="prot_i">
                      <div>{{ ctem.price }}</div>
                      <div>X{{ ctem.quantity }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="t_btm_r table_width">
       
              <div>{{ item.selfCode }}</div>
          
              <div>
                <div v-if="item.approveStatus == '1'" style="color: #009b58">
                  {{ item.approveStatusVal }}
                </div>
                <div v-if="item.approveStatus == '0'" style="color: #c8161d">
                  {{ item.approveStatusVal }}
                </div>
              </div>
           
              <div>
                {{ item.creationTime }}
              </div>
           
              <div>{{ item.remark }}</div>

            
            </div>
          </div>
        </div>
      </template> -->
    </div>

    <pagination
      v-show="total > 0"
      :total="total"
      :page.sync="queryParams.pageNum"
      :limit.sync="queryParams.pageSize"
      @pagination="getDataList"
    />

    <!-- 订单自提 -->
    <el-dialog :title="$t('ENU_APPROVE_B_251')" :visible.sync="pickShow" width="35%" center>
      <div class="diaBox">
        <el-form :model="pickForm" label-width="auto" ref="pickForm">
          <el-form-item :label="$t('w_0052')" prop="phone">
            <el-input disabled v-model="pickForm.phone"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_WAL_22')" prop="remark">
            <el-input :placeholder="$t('S_C_70')" v-model="pickForm.remark"></el-input>
          </el-form-item>
          <div>
            <div class="membertitle">{{$t('MY_CK_20')}}：</div>
            <div class="membercontent">
              1、{{$t('MY_CK_21')}}<br />
              2、{{$t('MY_CK_22')}}<br />
              3、{{$t('MY_CK_23')}}<br />
              4、{{$t('MY_CK_24')}}<br />
            </div>
          </div>
          <div style="text-align: center">
            <el-button class="saveBtn" @click="savePick('pickForm')"
              >{{$t('MY_CK_25')}}</el-button
            >
          </div>
        </el-form>
      </div></el-dialog
    >

    <!-- 自提成功 -->
    <el-dialog :title="$t('ENU_APPROVE_B_251')" :visible.sync="successShow" width="30%" center>
      <div class="successdiv">
        <div class="successtop">
          <div class="ztm">{{$t('MY_CK_26')}}：{{ selfCode }}</div>
          <el-button type="text" class="copy" @click="copyCode(selfCode)"
            >{{$t('MY_CK_27')}}</el-button
          >
        </div>
        <div class="word1">
          {{$t('MY_CK_23')}}
        </div>
        <div> {{$t('MY_CK_28')}}：400-111-2818</div>
        <div class="word2">
          <el-button class="saveBtn" @click="successShow = false"
            >{{$t('MY_WAL_23')}}</el-button
          >
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import * as sel from "@/api/selfservice.js";
export default {
  components: {},
  data() {
    return {
      creationTime: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 50,
      },
      total: 0,
      isTab: 0,
      orderLists: [],
      selfLists: [],
      pickForm: {
        remark: "",
      },
      pickShow: false,
      successShow: false,
      selfCode: "",
    };
  },
  watch: {
    isTab(n, o) {
      this.getDataList();
    },
  },
  mounted() {
    this.getDataList();
  },
  methods: {
    //点击查看
    clickFind(item){
        this.selfCode = item.selfCode
        this.successShow = true
    },
    //复制
    copyCode(content) {
      if (window.clipboardData) {
        window.clipboardData.setData("text", content);
      } else {
        (function (content) {
          //oncopy 事件在用户拷贝元素上的内容时触发。
          document.oncopy = function (e) {
            e.clipboardData.setData("text", content);
            e.preventDefault();
            document.oncopy = null;
          };
        })(content);
        document.execCommand("Copy");
      }
      this.$message({
        message:this.$t('MY_CK_29'),
        type: "success",
      });
    },
    //确认自提
    savePick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          sel.saveSelfPick(this.pickForm).then((res) => {
            if (res.code == 200) {
              this.successShow = true;
              this.selfCode = res.data.selfCode;
              this.$refs.pickForm.clearValidate();
              this.pickShow = false;
              this.getDataList();
            }
          });
        }
      });
    },
    //订单自提
    pickOrder(item) {
      this.pickForm.remark = "";
      this.pickForm.orderCode = item.orderCode;
      this.pickShow = true;
      this.pickForm.phone = item.recPhone;
    },
    changeTime() {
      this.queryParams.creationTimeStart = this.creationTime[0];
      this.queryParams.creationTimeEnd = this.creationTime[1];
    },
    reset() {
      this.creationTime = [];
      this.queryParams = {
        pageNum: 1,
        pageSize: 50,
      };
      this.getDataList();
    },
    getDataList() {
      sel.getOrderSelfList(this.queryParams).then((res) => {
        this.orderLists = res.rows;
        this.total = res.total;
        const regex = new RegExp('<img', 'gi');
        this.orderLists.forEach(item=>{
         if(item.rejectMsg){
          item.rejectMsg= item.rejectMsg.replace(regex, `<img style="max-width: 200px;"`);
         }
        })
      });
    },
  },
};
</script>
  
 <style lang="scss" scoped>
 .table_top {
    padding: 20px 0;
    text-align: right;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #c8161d;
    padding-right: 20px;
  }
::v-deep .successdiv {
  padding: 0 20px;
  .successtop {
    display: flex;
    align-items: center;
    justify-content: center;
    .ztm {
      color: #009b58;
      font-size: 30px;
      font-weight: 600;
    }
    .copy {
      color: #d61820;
      margin-left: 20px;
    }
  }
  .word1 {
    margin-top: 20px;
    line-height: 24px;
    font-size: 14px;
  }
  .word2 {
    text-align: center;
    margin-top: 20px;
  }
}

.saveBtn {
  margin-top: 20px;
  width: 209px;
  height: 48px;
  font-size: 16px;
  background-color: rgba(214, 24, 32, 1);
  color: #ffffff;
}
::v-deep .diaBox {
  .membertitle {
    padding-top: 20px;
    font-weight: 600;
    color: #333333;
    font-size: 14px;
    line-height: 24px;
  }
  .membercontent {
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    line-height: 24px;
  }
  //   padding: 0 60px;
  .el-form-item {
    line-height: 49px;
  }
  .el-form-item__label {
    font-size: 16px;
    font-weight: 500;
  }
  .el-input__inner {
    width: 295px;
    height: 49px;
    padding: 0 20px;
    font-size: 16px;
  }

  .saveBtn {
    margin-top: 20px;
    width: 209px;
    height: 48px;
    font-size: 16px;
    background-color: rgba(214, 24, 32, 1);
    color: #ffffff;
  }
}
.contain {
  padding-top: 20px;
  .kuang {
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    padding-top: 20px;
  }
}
.table {
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
  border-radius: 10px 10px 10px 10px;
  margin-top: 40px;
  font-size: 14px;
  .tabList {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: #666666;
    padding: 20px 20px 0 20px;
    .tab_i {
      margin-right: 70px;
      border-bottom: 5px solid #fff;
      padding-bottom: 10px;
      position: relative;
      cursor: pointer;
      .qiu {
        position: absolute;
        width: 20px;
        height: 20px;
        background: #b42b2a;
        top: -10px;
        right: -10px;
        border-radius: 50%;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        line-height: 20px;
      }
    }
    .tabAct {
      color: #b42b2a;
      border-bottom: 5px solid #b42b2a;
    }
  }
}

.table_tit {
  background: #eeeeee;
  padding: 30px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
}
.table_tit2 {
  width: 8%;
  text-align: center;
}
.t_btm_a {
  display: flex;
  align-items: center;
  padding: 20px 30px;
}
.t_btm_l {
  width: 20%;
}
.t_btm_r {
  // width: 8%;
  text-align: center;
  display: flex;
  flex: 1;
  align-items: center;
}
.table_width {
  text-align: center;
  div {
    flex: 1;
    word-break: break-all;
  }
  // div:nth-child(11) {
  //   width: 8%;
  // }
}
.tab_i1 {
  // padding-left: 30px;
  display: flex;
  align-items: center;
  img {
    width: 64px;
    height: 64px;
    border-radius: 8px 8px 8px 8px;
  }
}
.cp_i1 {
  display: flex;
  align-items: center;
  margin: 5px 0;
  img {
    width: 28px;
    height: 28px;
    border-radius: 6px;
  }
  .cp_i1_1 {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #333333;
    margin-left: 10px;
    margin-right: 10px;
  }
  .cp_i1_2 {
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #666666;
  }
}
.tab_i1_1 {
  // display: flex;
  text-align: left;
  flex: 1;
  padding-left: 10px;
  .prot {
    margin-bottom: 20px;
  }
  .prot_i {
    width: 50% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.t_top {
  display: flex;
  align-items: center;
  padding: 18px 0 15px 30px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  span {
    margin-right: 60px;
  }
}
.t_btm {
  // display: flex;
  // align-items: center;
  // padding: 20px 0px 20px 30px;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0,0,0,0.1);
}
.t_btm:last-child{
  border-bottom: none;
}
.order_i {
  background: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(204, 204, 204, 0.5);
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 20px;
}
.del {
  div:nth-child(2) {
    width: 100%;
  }
  div:nth-child(3) {
    width: 100%;
  }
  div:nth-child(1) {
    width: 100%;
    color: #3499ff;
  }
}
.del1 {
  color: #d61820;
  cursor: pointer;
  margin-top: 10px;
}
.del2 {
  color: #ff9008;
  cursor: pointer;
}
.del3 {
  color: #d61820;
  cursor: pointer;
}
.del4 {
  color: #d61820;
  cursor: pointer;
}
::v-deep .el-range-editor.el-input__inner {
  width: auto;
}
::v-deep .el-select {
  width: 100%;
}
</style>