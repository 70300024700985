<template>
  <div class="contain">
    <div class="tablebox" v-if="tableData.length > 0">
      <div
        class="card"
        v-for="(item, index) in tableData"
        :style="{ backgroundImage: `url(${item.backgroundImg})` }"
        :key="index"
      >
        <div class="cardtopflex">
          <div class="cardtopflex2">
            <div class="imgbox"><img :src="item.logo" alt="" /></div>
            <div class="titlebox">
              <div class="cardtitle">{{ item.bankName }}</div>
              <div class="cardtitle2">{{ $t("MY_ORD_67") }}</div>
            </div>
          </div>
          <div class="flexs_1" v-if="item.defaultCard == 1">
            <div class="qiu"></div>
            <div v-if="pkCountry == 1">{{ $t("w_0263") }}</div>
            <div style="color: #fff; font-weight: bold" v-else>
              {{ $t("w_0263") }}
            </div>
          </div>
          <div class="flexs_1" v-else>
            <div
              style="color: #fff; font-weight: bold; cursor: pointer"
              @click="setDefault(item.pkId)"
            >
              {{ $t("PER_DA_23") }}
            </div>
          </div>
        </div>
        <div class="cardNumber">{{ item.cardNumber }}</div>
        <div v-if="pkCountry == 1" class="cardfooter" @click="removeBind(item)">
          [{{ $t("MY_ORD_68") }}]
        </div>
        <div
          v-else
          style="font-weight: bold; color: #fff"
          class="cardfooter"
          @click="removeBind(item)"
        >
          [{{ $t("MY_ORD_68") }}]
        </div>
      </div>
    </div>
    <div v-else-if="!tableData.length > 0 && pkCountry == 1">
      <img
        style="width: 517px; height: 315px"
        src="../assets/images/nullBankCard2.png"
        alt=""
      />
    </div>
    <el-button class="footerbtn" @click="bindBank(true)">{{
      $t("w_0179")
    }}</el-button>
    <WalletBindBank :isAdd="isAdd" @closeBind="closeBind"></WalletBindBank>
  </div>
</template>
          
  <script>
import { getBankList, removeBankBind, updateDefault } from "@/api/wallet.js";
import WalletBindBank from "@/components/walletBindBank.vue";
export default {
  name: "walletBalance",
  components: {
    WalletBindBank,
  },
  data() {
    return {
      tableData: [],
      // 绑定银行卡弹框
      isAdd: false,
      pkCountry: "",
    };
  },
  watch: {
    $route(n, o) {
      this.specialArea = 4;
    },
  },
  created() {},
  mounted() {
    this.specialArea = 4;
    this.research();
    this.pkCountry = parseInt(localStorage.getItem("pkCountry"));
  },
  methods: {
    //设为默认
    setDefault(pkId) {
      updateDefault({ pkId: pkId }).then((res) => {
        if (res.code == 200) {
          this.$message({
            type: "success",
            message: res.msg,
          });
          this.research();
        }
      });
    },
    //解除绑定
    removeBind(item) {
      this.$confirm(this.$t("MY_ORD_69"), "", {
        confirmButtonText: this.$t("w_0035"),
        cancelButtonText: this.$t("ENU_P_TYPE0"),
        type: "warning",
      }).then(() => {
        if (item.defaultCard == 1) {
          this.$confirm("当前解绑为默银行卡，请设置为另一张卡为默认银行卡", "", {
            confirmButtonText: this.$t("w_0035"),
            cancelButtonText: this.$t("ENU_P_TYPE0"),
            type: "warning",
          }).then(() => {
            removeBankBind({ pkId: item.pkId }).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.research();
          }
        });
          });
        }else{
          removeBankBind({ pkId: item.pkId }).then((res) => {
          if (res.code == 200) {
            this.$message({
              type: "success",
              message: res.msg,
            });
            this.research();
          }
        });
        }
        
      });
    },
    closeBind() {
      this.isAdd = false;
      this.research();
    },
    //点击绑定银行卡
    bindBank(val) {
      this.isAdd = true;
      this.jdTl = val;
    },
    research() {
      getBankList().then((res) => {
        this.tableData = res.data;
      });
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>
          
  <style lang="scss" scoped>
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
.contain {
  background: rgba(249, 249, 249, 1);
  box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
  border-radius: 10px 10px 10px 10px;
  padding: 20px;
  margin-top: 20px;
  text-align: center;

  .tablebox {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    .card {
      width: 560px;
      // background-image: url(../assets/images/bankCard.png);
      background-size: 100% 100%;
      border-radius: 20px;
      margin: 20px;
      padding: 40px;
      .cardtopflex {
        display: flex;
        align-items: center;
        justify-content: space-between;
        align-items: center;
        .flexs_1 {
          display: flex;
          align-items: center;
          margin-right: 20px;
          color: #ffffff;
          .qiu {
            margin-right: 10px;
            width: 20px;
            height: 20px;
            border-radius: 50%;
            background: rgb(47, 236, 47);
          }
        }

        .cardtopflex2 {
          display: flex;
          align-items: center;
          .imgbox {
            margin-right: 20px;
            img {
              width: 60px;
              height: 54px;
            }
          }
          .titlebox {
            color: #ffffff;
            text-align: left;
            .cardtitle {
              font-size: 32px;
            }
            .cardtitle2 {
              font-size: 18px;
              margin-top: 5px;
            }
          }
        }
      }
      .cardNumber {
        font-size: 38px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        margin-top: 50px;
      }
      .cardfooter {
        text-align: right;
        font-size: 18px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #ffffff;
        margin-top: 100px;
        cursor: pointer;
      }
    }
  }
  .footerbtn {
    width: 330px;
    height: 60px;
    color: #ffffff;
    background: #d61820;
    margin-bottom: 20px;
    margin-top: 120px;
    font-size: 18px;
  }
}
</style>