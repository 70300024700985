<template>
  <div class="contain">
    <div class="rightbox" style="width: 100%">
      <div class="topbox">
        <el-form ref="form" :model="queryParams" label-width="80px">
          <el-row>
            <el-col :span="4">
              <el-form-item :label="$t('N_I_163')" prop="tradeType">
                <el-select clearable v-model="queryParams.tradeType" :placeholder="$t('CK_KS_38')">
                  <el-option
                    v-for="(item, index) in tradeTypeList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item :label="$t('w_0294')" prop="pkAccount">
                <el-select clearable v-model="queryParams.pkAccount" :placeholder="$t('CK_KS_38')">
                  <el-option
                    v-for="(item, index) in pkAccountList"
                    :key="index"
                    :label="item.pkTransactionKeyVal"
                    :value="item.pkId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :label="$t('N_I_160')"  prop="teamCode">
                <el-date-picker
                  v-model="thetime"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  :range-separator="$t('w_0139')"
                  :start-placeholder="$t('CK_KS_4')"
                  :end-placeholder="$t('CK_KS_5')"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>

            <el-col :span="4">
              <div class="searchbox">
                <el-button class="btn2" @click="research">{{ $t('N_I_183') }}</el-button>
                <el-button class="btn1" @click="chongzhi">{{ $t('CK_KS_6') }}</el-button>
               
              </div>
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tablebox">
        <el-table
          :data="tableData"
          :header-cell-style="{ background: '#EEEEEE' }"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            align="center"
            type="index"
            :label="$t('w_0295')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="tradeCode"
            :label="$t('w_0296')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="pkAccountVal"
            :label="$t('w_0294')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="tradeTypeVal"
            :label="$t('N_I_163')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="tradeAmount"
            :label="`${$t('w_0297')}(${userInfo.currencyIcon})`"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="tradeBalance"
            :label="`${$t('w_0298')}(${userInfo.currencyIcon})`"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="creationTime"
            :label="$t('N_I_160')" 
          ></el-table-column>
          <el-table-column
            align="center"
            prop="remarks"
            :label="$t('MY_WAL_22')"
           
          >
          <template scope="scope">
           <span v-if="scope.row.montage==0"> {{ scope.row.tradeTypeVal  }}{{ scope.row.sourceCode }}</span>
           <span v-else> {{ scope.row.remarks }}</span>
          </template>
        </el-table-column>
        </el-table>
        <pagination
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="research"
        />
      </div>
    </div>
  </div>
</template>
          
  <script>
import pagination from "@/components/pagination.vue";
import { mapGetters } from 'vuex'
import {
  getTransactionDetails,
  getTradeTypeList,
  getEnumsAccountList,
} from "@/api/wallet.js";
export default {
  name: "walletBalance",
  components: {
    pagination,
  },
  props: {
    theId: {
      type: String,
    },
  },
  computed: {
    ...mapGetters(['userInfo', 'specialCarNum']),
  },
  data() {
    return {
      thetime: "",
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 50,
        tradeType: "",
        pkAccount: "",
        startDate: "",
        endDate: "",
      },
      tradeTypeList: [], //交易类型
      pkAccountList: [], //账户类型
    };
  },
  watch: {
    $route(n, o) {
      this.specialArea = 4;
    },
  },
  created() {
    this.queryParams.pkAccount = this.theId || "";
  },
  mounted() {
    this.specialArea = 4;
    this.research();
    this.getTradeTypeList();
    this.getEnumsAccountList();
  },
  methods: {
    getTradeTypeList() {
      getTradeTypeList().then((res) => {
        this.tradeTypeList = res.data;
      });
    },
    getEnumsAccountList() {
      getEnumsAccountList().then((res) => {
        this.pkAccountList = res.data;
      });
    },

    chongzhi() {
      this.thetime = "";
      this.queryParams = {
        pageNum: 1,
        pageSize: 50,
        tradeType: "",
        pkAccount: "",
        startDate: "",
        endDate: "",
      };
      this.research();
    },
    research() {
      if (this.thetime) {
        this.queryParams.startDate = this.thetime[0];
        this.queryParams.endDate = this.thetime[1];
      }
      getTransactionDetails(this.queryParams).then((res) => {
        const tableDataWithRow = res.rows.map((item, index) => {
          return { ...item, row: index + 1 };
        });
        this.tableData = tableDataWithRow;
        this.total = res.total;
      });
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>
          
          <style lang="scss" scoped>
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
.contain {
  background: rgba(249, 249, 249, 1);
  height: 100vh;
  display: flex;
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }
    .actArea {
      color: #b42b2a;
    }
  }
  .topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 40px 0;
    padding: 30px 16px 10px 16px;
    .searchbox {
      display: flex;
      align-items: center;
      .btn1 {
        background: #cccccc;
        border-radius: 5px 5px 5px 5px;
        color: #ffffff;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
      }
    }
  }
  .tablebox {
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    .tabletop {
      text-align: right;
      padding: 20px;
      .tabletoptitle1 {
        font-size: 18px;
      }
      .tabletoptitle2 {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}
</style>