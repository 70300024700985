<template>
  <div class="contain">
    <div class="main_r">
      <div class="menuList">
        <div
          v-for="item in vipList"
          @click="isMenu = item.id"
          :class="['menu_i', isMenu == item.id ? 'actMenu' : '']"
          :key="item.id"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="xian"></div>
    </div>
    <!-- 可申请信息 -->
    <div v-if="isMenu == 0">
      <el-table
        :data="registeredList"
        :header-cell-style="{ background: '#EEEEEE' }"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          align="center"
          prop="memberCode"
          :label="$t('N_I_124')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="memberName"
          :label="$t('CK_KS_14')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="phone"
          :label="$t('w_0052')"
        ></el-table-column>
        <!-- <el-table-column
          align="center"
          prop="changeNum"
          :label="可变更次数"
        ></el-table-column> -->
        <el-table-column align="center" prop="" :label="$t('MY_ORD_14')">
          <template slot-scope="scope">
            <el-button
              style="color: #ffad41"
              @click="applyChange(scope.row)"
              type="text"
              size="small"
              >{{ $t("PER_DA_35") }}</el-button
            >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <!-- 已申请信息 -->
    <div v-if="isMenu == 1">
      <el-table
        :data="registeredList2"
        :header-cell-style="{ background: '#EEEEEE' }"
        :row-class-name="tableRowClassName"
      >
        <el-table-column
          align="center"
          prop="memberCode"
          :label="$t('N_I_124')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="memberName"
          :label="$t('MY_CK_2')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="changeMemberName"
          :label="$t('MY_CK_3')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="phone"
          :label="$t('MY_CK_4')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="changePhone"
          :label="$t('MY_CK_5')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="amount"
          :label="`${$t('MY_CK_6')}(￥)`"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="creationTime"
          :label="$t('CK_KS_51')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="approveStatusVal"
          :label="$t('MY_CK_7')"
        >
        <template slot-scope="scope">
            <div v-if="scope.row.approveStatus == 3">
              <el-popover placement="right"
                          width="400"
                          trigger="hover">
                <div v-html="scope.row.rejectMsg"></div>
                <div style="color:#b42b2a;cursor: pointer;" slot="reference">{{ scope.row.approveStatusVal }}</div>
              </el-popover>
            </div>
            <div v-else>{{ scope.row.approveStatusVal }}</div>
          </template>
      </el-table-column>
        <el-table-column
          align="center"
          prop="approveTime"
          :label="$t('CK_KS_52')"
        ></el-table-column>
        <el-table-column
          align="center"
          prop="remark"
          :label="$t('MY_WAL_22')"
        ></el-table-column>
      </el-table>
    </div>

    <el-dialog
      :title="$t('ENU_APPROVE_B_132')"
      :visible.sync="regShow"
      width="45%"
      center
    >
      <div class="diaBox">
        <el-form
          :model="regForm"
          :rules="rerules"
          label-width="auto"
          ref="regForm"
        >
          <el-form-item :label="$t('CK_KS_14')" prop="memberName">
            <el-input disabled v-model="regForm.memberName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('w_0052')" prop="phone">
            <el-input disabled v-model="regForm.phone"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_CK_8')" prop="changeMemberName">
            <el-input v-model="regForm.changeMemberName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_CK_9')" prop="changePhone">
            <el-input v-model="regForm.changePhone"></el-input>
          </el-form-item>
          <el-form-item :label="$t('w_0043')" prop="verificationCode">
            <div style="display: flex; align-items: center">
              <el-input
                class="thecode"
                v-model="regForm.verificationCode"
              ></el-input>
              <div
                class="hqyzm1"
                :class="isSend ? 'hui' : ''"
                @click="startTime"
              >
                {{ getCode }}
              </div>
            </div>
          </el-form-item>
          <el-form-item
            class="oldcard"
            :label="$t('MY_CK_10')"
            prop="description"
          >
            <el-input
              v-model="regForm.description"
              :placeholder="$t('S_C_70')"
              type="textarea"
              :rows="2"
            ></el-input>
          </el-form-item>
          <el-form-item
            class="oldcard"
            :label="$t('MY_CK_11')"
            prop="idFront"
          >
            <ImageUpload v-model="regForm.idFront"  :backimg="
                require('../../assets/images/markerSpace/idCard-img1.png')
              "/>
            <ImageUpload v-model="regForm.idBack"  :backimg="
                require('../../assets/images/markerSpace/idCard-img2.png')
              "/>
          </el-form-item>
          <el-form-item
            class="newcard"
            :label="$t('MY_CK_12')"
            prop="changeIdFront"
          >
            <ImageUpload
              :backimg="
                require('../../assets/images/markerSpace/idCard-img1.png')
              "
              v-model="regForm.changeIdFront"
            />
            <ImageUpload
              :backimg="
                require('../../assets/images/markerSpace/idCard-img2.png')
              "
              v-model="regForm.changeIdBack"
            />
          </el-form-item>
          <el-form-item :label="`${$t('MY_CK_6')}(￥)`" prop="amount">
            <el-input disabled v-model="regForm.amount"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_WAL_22')" prop="remark">
            <el-input v-model="regForm.remark"></el-input>
          </el-form-item>
          <div style="text-align: center">
            <el-button class="saveBtn" @click="saveReg('regForm')">{{
              $t("MY_CK_13")
            }}</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import ImageUpload from "@/components/ImageUpload/index.vue";
import * as sel from "@/api/selfservice.js";
import { getVerification } from "@/api/wallet.js";
export default {
  components: { ImageUpload },
  data() {
    return {
      vipList: [
        {
          text: this.$t("MY_CK_14"),
          id: 0,
        },
        {
          text: this.$t("MY_CK_15"),
          id: 1,
        },
      ],
      isMenu: 0,
      registeredList: [],
      registeredList2: [],
      regForm: {},
      rerules: {
        changeMemberName: [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
        ],
        changePhone: [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
          { min: 8, max: 20, message: this.$t("S_C_70"), trigger: "blur" },
        ],

        verificationCode: [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
        ],
        description: [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
        ],
        idFront:[
          { required: true, message: this.$t("MY_CK_16"), trigger: "change" },
        ],
        changeIdFront: [
          { required: true, message: this.$t("MY_CK_16"), trigger: "change" },
        ],
      },
      regShow: false,
      isSend: false,
      beginTime: 60,
      getCode: this.$t("w_0055"),
      timer: "",
      beginTime: 60,
    };
  },
  created() {
    this.getSearch();
  },
  methods: {
    saveReg(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          sel.saveChangeDomicile(this.regForm).then((res) => {
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: "success",
              });
              this.$refs.regForm.clearValidate();
              this.regShow = false;
              this.getSearch();
            }
          });
        }
      });
    },
    startTime() {
      if (!this.regForm.changePhone) {
        this.$message({
          message: this.$t('MY_WAL_47'),
          type: "warning",
        });
        return;
      } else {
        if (this.isSend) return;
        this.isSend = true;
        this.getCode = this.beginTime +this.$t('w_0058');
        this.timer = setInterval(() => {
          this.beginTime--;
          this.getCode = this.beginTime +this.$t('w_0058');
          if (this.beginTime < 0) {
            clearInterval(this.timer);
            this.getCode = this.$t('w_0055');
            this.beginTime = 60;
            this.isSend = false;
          }
        }, 1000);
        getVerification({ phone: this.regForm.changePhone }).then((res) => {
          this.$message({
            message: this.$t('MY_WAL_49'),
            type: "success",
          });
        });
      }
    },
    applyChange(row) {
      clearInterval(this.timer);
      this.getCode = this.$t('w_0055');
      this.beginTime = 60;
      this.isSend = false;
      sel.showChangeDomicile().then((res) => {
        this.regForm = res.data;
        this.regShow = true;
      });
    },
    getSearch() {
      sel.getApplicationInfoList().then((res) => {
        this.registeredList = [res.data];
      });
      sel.getAppliedInfoList().then((res) => {
        this.registeredList2 = res.data;
        const regex = new RegExp('<img', 'gi');
        this.registeredList2.forEach(item=>{
         if(item.rejectMsg){
          item.rejectMsg= item.rejectMsg.replace(regex, `<img style="max-width: 200px;"`);
         }
        })
      });
    },
    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}
::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
::v-deep .diaBox {
  padding: 0 60px;
  .el-form-item {
    line-height: 60px;
  }
  .el-form-item__label {
    font-size: 18px;
    font-weight: 500;
  }
  .el-input__inner {
    width: 534px;
    height: 60px;
    padding: 0 20px;
    font-size: 16px;
  }
  .saveBtn {
    margin-top: 20px;
    width: 209px;
    height: 48px;
    font-size: 16px;
    background-color: rgba(214, 24, 32, 1);
    color: #ffffff;
  }
}

::v-deep .thecode .el-input__inner {
  width: 280px;
}
.hqyzm1 {
  background: #d5251d;
  border-radius: 8px;
  padding: 0 7px;
  color: #fff;
  font-size: 18px;
  min-width: 190px;
  height: 65px;
  display: flex;
  align-items: center;
  margin-right: 20px;
  justify-content: center;
  cursor: pointer;
}
.hui {
  opacity: 0.5;
}
::v-deep .oldcard .el-form-item__content {
  display: flex;
  .el-image {
    width: 235px !important;
    height: 137px !important;
  }
  .el-upload--picture-card:nth-child(1) {
    margin-right: 60px !important;
  }
}
::v-deep .newcard .el-form-item__content {
  display: flex;
  .el-image {
    width: 235px !important;
    height: 137px !important;
  }
  .el-upload--picture-card:nth-child(1) {
    margin-right: 60px !important;
  }
}
.contain {
  background: #ffffff;
  box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
  border-radius: 8px 8px 8px 8px;
  margin: 20px 0 20px 0;
  .main_r {
    .menuList {
      display: flex;
      margin-top: 20px;
      padding: 5px 24px;
      box-sizing: border-box;
      .menu_i {
        margin-right: 70px;
        line-height: 50px;
        cursor: pointer;
      }
      .actMenu {
        border-bottom: 5px solid #b42b2a;
      }
    }
    .xian {
      height: 5px;
      background: rgba(0, 0, 0, 0.05);
      margin-top: -5px;
    }
  }
}
</style>