<template>
  <div class="contain">
    <div class="kuang">
      <el-form ref="queryParams"
               :model="queryParams"
               label-width="100px">
        <el-row>
          <el-col :span="6">
            <el-form-item :label="$t('CK_KS_51')">
              <el-input v-model="queryParams.orderCode"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="4"
                  style="margin-left: 20px">
            <el-button style="
                background-color: #ffffff;
                color: #cccccc;
                border-radius: 4px 4px 4px 4px;
                opacity: 1;
                border: 1px solid #cccccc;
              "
                       type="info"
                       @click="reset">{{$t('CK_KS_6')}}</el-button>
            <el-button type="danger"
                       style="background-color: #d61820"
                       @click="getDataList">{{ $t('N_I_183') }}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <div class="tablebox">
      <div class="table_top">{{$t('MY_CK_30')}}</div>
      <el-table :data="orderLists"
                :header-cell-style="{ background: '#EEEEEE' }">
        <el-table-column align="center"
                         prop="orderCode"
                         min-width="150px"
                         :label="$t('MY_ORD_1')"></el-table-column>
        <el-table-column align="center"
                         prop="orderTypeVal"
                         :label="$t('MY_ORD_2')"></el-table-column>
        <el-table-column align="center"
                         prop="orderStatusVal"
                         :label="$t('MY_ORD_9')"></el-table-column>
        <el-table-column align="center"
                         prop="memberCode"
                         :label="$t('N_I_124')"></el-table-column>
        <el-table-column align="center"
                         prop="memberName"
                         :label="$t('CK_KS_14')"></el-table-column>
        <el-table-column align="center"
                         prop="recName"
                         :label="$t('MY_ORD_10')"></el-table-column>
        <el-table-column align="center"
                         prop="recPhone"
                         :label="$t('w_0052')"></el-table-column>
        <el-table-column align="center"
                         prop="recProvinceVal"
                         :label="$t('MY_ORD_11')"></el-table-column>
        <el-table-column align="center"
                         prop="recCityVal"
                         :label="$t('MY_ORD_12')"></el-table-column>
        <el-table-column align="center"
                         prop="recCountyVal"
                         :label="$t('MY_ORD_13')"></el-table-column>
        <el-table-column align="center"
                         prop="recAddress"
                         min-width="120px"
                         :label="$t('S_C_27')"></el-table-column>
        <el-table-column align="center"
                         prop="approveStatusVal"
                         :label="$t('MY_CK_7')">
          <template slot-scope="scope">
            <div v-if="scope.row.approveStatus == 3">
              <el-popover placement="right"
                          width="400"
                          trigger="hover">
                <div v-html="scope.row.rejectMsg"></div>
                <div style="color:#b42b2a;cursor: pointer;" slot="reference">{{ scope.row.approveStatusVal }}</div>
              </el-popover>
            </div>
            <div v-else>{{ scope.row.approveStatusVal }}</div>
          </template>
        </el-table-column>
        <el-table-column align="center"
                         prop="time"
                         :label="$t('MY_ORD_14')">
          <template slot-scope="scope">
            <el-popover placement="top-start"
                        title=""
                        width="200"
                        trigger="hover"
                        :content="scope.row.remark">
              <div slot="reference"
                   style="cursor: pointer; color: #3499ff">
                {{$t('MY_WAL_22')}}
              </div>
            </el-popover>

            <el-button style="color: #f3a900"
                       @click="pickOrder(scope.row)"
                       type="text"
                       size="small">
              {{$t('ENU_TRADE_T_830')}}
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <!-- table 可申请订单 -->
    <!-- <div class="table">
      <div class="tabList">
        <div
          :class="['tab_i', isTab === item.value ? 'tabAct' : '']"
          v-for="item in orderStatusList"
          @click="isTab = item.value"
          :key="item.value"
        >
          {{ item.label }}
        </div>
      </div>
      <template v-if="isTab == 0">
        <div class="table_tit">
          <div class="t_btm_l">
            <div class="tab_i1">订单详情</div>
          </div>
          <div class="t_btm_r table_width">
            <div>订单类型</div>
            <div>订单金额</div>
            <div>报单编号</div>
            <div>产品业绩(PV)</div>
            <div>产品业绩(BV)</div>
            <div>{{$t('N_I_124')}}</div>
            <div>订单状态</div>
            <div>收货人</div>
            <div>联系方式</div>
            <div>{{ $t('w_0068') }}</div>
            <div>{{ $t('MY_ORD_14') }}</div>
          </div>
        </div>
        <div v-for="(item, index) in orderLists" class="order_i" :key="index">
          <div class="t_top">
            <span>订单编号：{{ item.orderCode }}</span>
            <span>下单时间：{{ item.creationTime }}</span>
            <span>{{$t('MY_ORD_3')}}：{{ item.payTime }}</span>
          </div>
          <div class="t_btm_a">
            <div class="t_btm_l">
              <div
                v-for="(ctem, cndex) in item.itemList"
                :key="cndex"
                class="t_btm"
              >
                <div class="tab_i1">
                  <img :src="ctem.cover" alt="" />
                  <div class="tab_i1_1">
                    <div class="prot">{{ ctem.productName }}</div>
                    <div class="prot_i">
                      <div>{{ ctem.price }}</div>
                      <div>X{{ ctem.quantity }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="t_btm_r table_width">
              <div>{{ item.orderTypeVal }}</div>

              <div>
                {{ item.orderAmount }}
              </div>

              <div>
                {{ item.creatorCode }}
              </div>

              <div>{{ item.orderAchieve }}</div>

              <div>{{ item.assAchievement }}</div>

              <div>{{ item.memberCode }}</div>

              <div v-if="item.orderStatus == '1'" style="color: #009b58">
                {{ item.orderStatusVal }}
              </div>
              <div v-if="item.orderStatus == '0'" style="color: #d61820">
                {{ item.orderStatusVal }}
              </div>
              <div v-if="item.orderStatus == '3'" style="color: #ffad41">
                {{ item.orderStatusVal }}
              </div>
              <div v-if="item.orderStatus == '5'" style="color: #ffad41">
                {{ item.orderStatusVal }}
              </div>

              <div>{{ item.recName }}</div>

              <div>{{ item.recPhone }}</div>

              <div>{{ item.address }}</div>

              <div class="del">
                <el-popover
                  placement="top-start"
                  title=""
                  width="200"
                  trigger="hover"
                  :content="item.remark"
                >
                  <div slot="reference" style="cursor: pointer">备注</div>
                </el-popover>
                <div class="del1" @click="pickOrder(item)">修改地址</div>
              </div>
            </div>
          </div>
        </div>
      </template>

      <template v-if="isTab == 1">
        <div class="table_tit">
          <div class="t_btm_l">
            <div class="tab_i1">订单详情</div>
          </div>
          <div class="t_btm_r table_width">
            <div>变更后省/州</div>
            <div>变更后市</div>
            <div>变更后区/县</div>
            <div>变更后详情地址</div>
            <div>审核状态</div>
            <div>申请时间</div>
            <div>备注</div>
          </div>
        </div>
        <div v-for="(item, index) in selfLists" class="order_i" :key="index">
          <div class="t_top">
            <span>订单编号：{{ item.orderCode }}</span>
            <span>下单时间：{{ item.creationTime }}</span>
            <span>{{$t('MY_ORD_3')}}：{{ item.payTime }}</span>
          </div>
          <div class="t_btm_a">
            <div class="t_btm_l">
              <div
                v-for="(ctem, cndex) in item.itemList"
                :key="cndex"
                class="t_btm"
              >
                <div class="tab_i1">
                  <img :src="ctem.cover" alt="" />
                  <div class="tab_i1_1">
                    <div class="prot">{{ ctem.productName }}</div>
                    <div class="prot_i">
                      <div>{{ ctem.price }}</div>
                      <div>X{{ ctem.quantity }}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="t_btm_r table_width">
              <div>{{ item.recProvinceVal }}</div>
              <div>{{ item.recCityVal }}</div>
              <div>{{ item.recCountyVal }}</div>

              <div>{{ item.newRecAddress }}</div>

              <div>
                <div v-if="item.approveStatus == '1'" style="color: #009b58">
                  {{ item.approveStatusVal }}
                </div>
                <div v-if="item.approveStatus == '0'" style="color: #c8161d">
                  {{ item.approveStatusVal }}
                </div>
              </div>
              <div>
                {{ item.creationTime }}
              </div>
              <div>{{ item.selfRemark }}</div>
            </div>
          </div>
        </div>
      </template>
    </div> -->

    <pagination v-show="total > 0"
                :total="total"
                :page.sync="queryParams.pageNum"
                :limit.sync="queryParams.pageSize"
                @pagination="getDataList" />

    <!-- 修改地址 -->
    <el-dialog :title="$t('ENU_TRADE_T_830')"
               :visible.sync="pickShow"
               width="40%"
               center>
      <div class="diaBox">
        <!-- <div style="color: #666666; font-size: 16px; margin-bottom: 20px">
          {{ $t('MY_CK_31') }}
        </div> -->
        <el-form :model="pickForm"
                 label-width="auto"
                 ref="pickForm">
          <el-form-item :label="$t('MY_ORD_1')"
                        prop="orderCode">
            <el-input disabled
                      v-model="pickForm.orderCode"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_ORD_10')"
                        prop="newRecName">
            <el-input v-model="pickForm.newRecName"></el-input>
          </el-form-item>
          <el-form-item :label="$t('w_0052')"
                        prop="newRecPhone">
            <el-input v-model="pickForm.newRecPhone"></el-input>
          </el-form-item>
          <el-form-item :label="$t('w_0068')"
                        class="dizhi"
                        prop="dizhi">
            <el-cascader v-model="pickForm.dizhi"
                         @change="setDizhi"
                         :options="areaData"
                         :props="regionParams"
                         :placeholder="$t('w_0069')"></el-cascader></el-form-item>
          <el-form-item :label="$t('S_C_27')"
                        prop="newRecAddress">
            <el-input maxlength="200"
                      v-model="pickForm.newRecAddress"></el-input>
          </el-form-item>
          <el-form-item :label="$t('w_0073')"
                        prop="">
            <div class="local">
              <div :class="['local_i', item.pkId == moren ? 'local_a' : '']"
                   v-for="item in addressList"
                   :key="item.pkId">
                <el-radio v-model="moren"
                          @input="checkRadio"
                          :label="item.pkId">{{
                }}</el-radio>
                <div class="local1">
                  {{ item.recArea }}{{ item.recAddress }}({{ item.recName }} {{ $t('MY_CK_33') }})
                </div>
              </div>
            </div>
          </el-form-item>
          <el-form-item :label="`${$t('邮费扣除')}(￥)`"
                        prop="newPostage">
            <el-input disabled
                      v-model="pickForm.newPostage"></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_WAL_22')"
                        prop="selfRemark">
            <el-input :placeholder="$t('S_C_70')"
                      v-model="pickForm.selfRemark"></el-input>
          </el-form-item>

          <div style="text-align: center">
            <el-button class="saveBtn1"
                       @click="pickShow = false">{{ $t('ENU_P_TYPE0') }}</el-button>
            <el-button class="saveBtn"
                       @click="savePick('pickForm')">{{ this.$t('w_0035')  }}</el-button>
          </div>
        </el-form>
      </div>
    </el-dialog>

    <!-- 修改成功 -->
    <el-dialog :title="$t('MY_CK_35')"
               :visible.sync="successShow"
               width="30%"
               center>
      <div class="successdiv">
        <div class="word1">
          {{$t('MY_CK_36')}}
        </div>
        <div>{{$t('MY_CK_28')}}：400-111-2818</div>
        <div class="word2">
          <el-button class="saveBtn"
                     @click="successShow = false">{{$t('MY_WAL_23')}}</el-button>
        </div>
      </div>
    </el-dialog>
  </div>
</template>
  
  <script>
import * as sel from '@/api/selfservice.js'
import * as api from '@/api/register.js'
export default {
  components: {},
  data() {
    return {
      creationTime: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 50,
      },
      total: 0,
      // isTab: 0,
      orderLists: [],
      selfLists: [],
      pickForm: {
        newRecName: '', //收货人
        newRecPhone: '', //手机
        newRecProvince: '', //收货省
        newRecCity: '', //收货市
        newRecCounty: '', //收货县
        recCnewRecCountyounty: '', //收货县
        newRecAddress: '', //详细地址
        orderCode: '', //订单编号
        newPostage: '', //新的邮费
        selfRemark: '', //备注
        payTime: '', //支付时间

        dizhi: [],
        nameList: '',
      },
      addressList: [],
      pickShow: false,
      successShow: false,
      areaData: [],
      regionParams: {
        label: 'name',
        value: 'id',
        children: 'children',
        expandTrigger: 'hover',
      },
      moren: '',
      postageData: {},
    }
  },
  // watch: {
  //   isTab(n, o) {
  //     this.getDataList();
  //   },
  // },
  mounted() {
    this.getDataList()
    this.getAllArea()
    // 获取地址
    this.getAddressList()
  },
  methods: {
    checkRadio() {
      this.$set(this.pickForm, 'dizhi', [])
      let arr = []
      this.addressList.forEach((item) => {
        if (item.pkId == this.moren) {
          // this.pickForm.newRecName = item.recName
          // this.pickForm.nameList = item.recArea
          // this.pickForm.newRecPhone = item.recPhone
          // this.pickForm.newRecAddress = item.recAddress
          // this.pickForm.newRecProvince = item.recProvince
          // this.pickForm.newRecCity = item.recCity
          // this.pickForm.newRecCounty = item.recCounty
          this.$set(this.pickForm, 'newRecName', item.recName)
          this.$set(this.pickForm, 'nameList', item.recArea)
          this.$set(this.pickForm, 'newRecPhone', item.recPhone)
          this.$set(this.pickForm, 'newRecAddress', item.recAddress)
          this.$set(this.pickForm, 'newRecProvince', item.recProvince)
          this.$set(this.pickForm, 'newRecCity', item.recCity)
          this.$set(this.pickForm, 'newRecCounty', item.recCounty)

          arr = [
            item.recProvince + '',
            item.recCity + '',
            (item.recCounty || '') + '',
          ]
        }
      })
      arr = arr.filter((s) => {
        return s && s.trim()
      })
      this.$set(this.pickForm, 'dizhi', arr)
      this.getPostAge()
      this.$forceUpdate()
    },
    //查询邮费
    getPostAge() {
      this.postageData.recProvince = this.pickForm.newRecProvince
      this.postageData.recCity = this.pickForm.newRecCity
      this.postageData.orderCode = this.pickForm.orderCode

      api.queryAdressPostage(this.postageData).then((res) => {
        this.$set(this.pickForm, 'newPostage', res.data.postage)
        this.$forceUpdate()
      })
    },
    getAddressList() {
      api.addressList({}).then((res) => {
        this.addressList = res.data
      })
    },
    setDizhi(e, val) {
      let arr = this.flatten(this.areaData)
      let nameList = ''
      arr.forEach((item) => {
        this.pickForm.dizhi.forEach((ctem) => {
          if (ctem == item.id) {
            nameList += item.name
          }
        })
      })
      this.pickForm.nameList = nameList
      this.pickForm.newRecProvince = e[0]
      this.pickForm.newRecCity = e[1]
      if (e.length > 2) {
        this.pickForm.newRecCounty = e[2]
      }
      this.getPostAge()
      this.$forceUpdate()
    },
    flatten(arr) {
      return arr.reduce((result, item) => {
        return result.concat(
          item,
          Array.isArray(item.children) ? this.flatten(item.children) : []
        )
      }, [])
    },
    getAllArea() {
      api.allArea().then((res) => {
        this.areaData = res.data
      })
    },
    //确定保存
    savePick(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          sel.saveSelfAddress(this.pickForm).then((res) => {
            if (res.code == 200) {
              this.successShow = true
              this.$refs.pickForm.clearValidate()
              this.pickShow = false
              this.getDataList()
            }
          })
        }
      })
    },
    //修改地址
    pickOrder(item) {
      this.pickForm = item
      this.pickShow = true
      this.postageData = {
        specialArea: item.specialArea,
        deliveryWay: item.deliveryWay,
        tranType: item.tranType,
        orderItemsParams: item.orderItemsParams,
      };
    },
    changeTime(val) {
      this.queryParams.creationTimeStart = val[0]
      this.queryParams.creationTimeEnd = val[1]
    },
    reset() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 50,
      }
      this.getDataList()
    },
    getDataList() {
      sel.getOderSelfList(this.queryParams).then((res) => {
        this.orderLists = res.rows
        this.total = res.total
        const regex = new RegExp('<img', 'gi');
        this.orderLists.forEach(item=>{
         if(item.rejectMsg){
          item.rejectMsg= item.rejectMsg.replace(regex, `<img style="max-width: 200px;"`);
         }
        })
      })
    },
  },
}
</script>
  
 <style lang="scss" scoped>
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
.tablebox {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
  border-radius: 10px 10px 10px 10px;
  .table_top {
    padding: 20px 0;
    text-align: right;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #c8161d;
  }
}
.local {
  width: 550px;

  // padding: 0px 60px;
  border-radius: 8px;
  border: 1px solid rgba(123, 120, 120, 0.5);
  .local_i {
    display: flex;
    align-items: center;
    // padding: 30px 90px;
    padding-left: 20px;
  }
  .local_a {
    padding-left: 20px;
    background: #eeeeee;
    border-radius: 8px 8px 8px 8px;
  }
  .local1 {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-right: 20px;
  }
}
::v-deep .el-radio {
  margin-right: 0;
}
::v-deep .successdiv {
  padding: 0 20px;
  .successtop {
    display: flex;
    align-items: center;
    justify-content: center;
    .ztm {
      color: #009b58;
      font-size: 30px;
      font-weight: 600;
    }
    .copy {
      color: #d61820;
      margin-left: 20px;
    }
  }
  .word1 {
    margin-top: 20px;
    line-height: 24px;
    font-size: 14px;
  }
  .word2 {
    text-align: center;
    margin-top: 20px;
  }
}

.saveBtn1 {
  margin-top: 20px;
  width: 209px;
  height: 48px;
  font-size: 16px;
  background-color: #cccccc;
  color: #ffffff;
}
.saveBtn {
  margin-top: 20px;
  width: 209px;
  height: 48px;
  font-size: 16px;
  background-color: rgba(214, 24, 32, 1);
  color: #ffffff;
}
::v-deep .diaBox {
  .membertitle {
    padding-top: 20px;
    font-weight: 600;
    color: #333333;
    font-size: 14px;
    line-height: 24px;
  }
  .membercontent {
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    line-height: 24px;
  }
  //   padding: 0 60px;
  .el-form-item {
    line-height: 49px;
  }
  .el-form-item__label {
    font-size: 16px;
    font-weight: 500;
  }
  .el-input__inner {
    width: 560px;
    height: 49px;
    padding: 0 20px;
    font-size: 16px;
  }

  .saveBtn {
    margin-top: 20px;
    width: 209px;
    height: 48px;
    font-size: 16px;
    background-color: rgba(214, 24, 32, 1);
    color: #ffffff;
  }
}
.contain {
  padding-top: 20px;
  .kuang {
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    padding-top: 20px;
  }
}
.table {
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
  border-radius: 10px 10px 10px 10px;
  margin-top: 40px;
  font-size: 14px;
  .tabList {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: #666666;
    padding: 20px 20px 0 20px;
    .tab_i {
      margin-right: 70px;
      border-bottom: 5px solid #fff;
      padding-bottom: 10px;
      position: relative;
      cursor: pointer;
      .qiu {
        position: absolute;
        width: 20px;
        height: 20px;
        background: #b42b2a;
        top: -10px;
        right: -10px;
        border-radius: 50%;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        line-height: 20px;
      }
    }
    .tabAct {
      color: #b42b2a;
      border-bottom: 5px solid #b42b2a;
    }
  }
}
.table_tit {
  background: #eeeeee;
  padding: 30px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
}
.table_tit2 {
  width: 8%;
  text-align: center;
}
.t_btm_a {
  display: flex;
  align-items: center;
  padding: 20px 30px;
}
.t_btm_l {
  width: 20%;
}
.t_btm_r {
  // width: 8%;
  text-align: center;
  display: flex;
  flex: 1;
  align-items: center;
}
.table_width {
  text-align: center;
  div {
    flex: 1;
    word-break: break-all;
  }
  // div:nth-child(11) {
  //   width: 8%;
  // }
}
.tab_i1 {
  // padding-left: 30px;
  display: flex;
  align-items: center;
}
.tab_i1_1 {
  // display: flex;
  text-align: left;
  flex: 1;
  padding-left: 10px;
  .prot {
    margin-bottom: 20px;
  }
  .prot_i {
    width: 50% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.t_top {
  display: flex;
  align-items: center;
  padding: 18px 0 15px 30px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  span {
    margin-right: 60px;
  }
}
.t_btm {
  display: flex;
  align-items: center;
  // padding: 20px 0px 20px 30px;
  margin-bottom: 10px;
  img {
    width: 64px;
    height: 64px;
    border-radius: 8px 8px 8px 8px;
  }
}
.order_i {
  background: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(204, 204, 204, 0.5);
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 20px;
}
.del {
  div:nth-child(2) {
    width: 100%;
  }
  div:nth-child(3) {
    width: 100%;
  }
  div:nth-child(1) {
    width: 100%;
    color: #3499ff;
  }
}
.del1 {
  color: #d61820;
  cursor: pointer;
  margin-top: 10px;
}
.del2 {
  color: #ff9008;
  cursor: pointer;
}
.del3 {
  color: #d61820;
  cursor: pointer;
}
.del4 {
  color: #d61820;
  cursor: pointer;
}
::v-deep .el-range-editor.el-input__inner {
  width: auto;
}
::v-deep .el-select {
  width: 100%;
}
</style>