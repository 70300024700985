import request from '@/util/request'

//户籍变更-可申请信息
export function getApplicationInfoList(params) {
    return request({
      url: '/member/api/change-domicile/application-info-list',
      method: 'get',
      params
    })
}

//户籍变更-已申请信息
export function getAppliedInfoList(params) {
    return request({
      url: '/member/api/change-domicile/applied-info-list',
      method: 'get',
      params
    })
}

//显示户籍变更信息
export function showChangeDomicile(params) {
    return request({
      url: '/member/api/change-domicile/show-change-domicile',
      method: 'get',
      params
    })
}

//保存户籍变更
export function saveChangeDomicile(data) {
  return request({
    url: '/member/api/change-domicile/change-domicile',
    method: 'post',
    data
  })
}

//修改收货地址列表
export function getOderSelfList(params) {
  return request({
    url: '/sale/api/my-order/selflist',
    method: 'get',
    params
  })
}


//订单自提列表
export function getOrderSelfList(params) {
  return request({
    url: '/sale/api/my-order/self-list',
    method: 'get',
    params
  })
}


//修改地址-已申请订单
export function getOderAddressSelfList(params) {
  return request({
    url: '/member/api/self-modify-address/list',
    method: 'get',
    params
  })
}

//订单自提
export function saveSelfPick(data) {
  return request({
    url: '/sale/api/order/self-pick-up',
    method: 'post',
    data
  })
}


//申请修改地址
export function saveSelfAddress(data) {
  return request({
    url: '/member/api/self-modify-address/putin',
    method: 'post',
    data
  })
}

//月度历史累计列表
export function getMonthAchieve(data) {
  return request({
    url: '/member/api/achieve/month-achieve?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize,
    method: 'post',
    data
  })
}

//月度历史累计明细列表
export function getMonthAchieveDetail(data) {
  return request({
    url: '/member/api/achieve/month-achieve-detail?pageNum=' + data.pageNum + '&pageSize=' + data.pageSize,
    method: 'post',
    data
  })
}

//真实奖衔下拉选
export function getAwardsList(params) {
  return request({
      url: '/system/pub/enums/award?pkCountry='+params.pkCountry,
      method: 'get',
      
  })
}
