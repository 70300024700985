<template>
  <div class="contain">
    <div class="rightbox" style="width: 100%">
      <div class="topbox" v-if="!ifshowBtn">
        <div>
          <el-button
            class="topbtn"
            v-if="userInfo.pkCountry == 1"
            @click="czShow = true"
            >{{ $t("MY_WAL_4") }}</el-button
          >
          <el-button class="topbtn" @click="checkReal(1)">{{
            $t("MY_WAL_5")
          }}</el-button>
          <el-button
            class="topbtn"
            v-if="!(userInfo.pkCountry == 1 && userInfo.pkGradeId == 1)"
            @click="checkReal(2)"
            >{{ $t("MY_WAL_6") }}</el-button
          >
        </div>
      </div>
      <div class="tablebox">
        <div
          class="card"
          :style="{ backgroundImage: 'url(' + item.backgroundImg + ')' }"
          v-for="(item, index) in tableData.memberWalletBalanceVOList"
          :key="index"
        >
          <div class="cardflex">
            <div class="cardtitle">
              <span>{{ item.pkAccountVal }}</span>
              <img
                @click="item.cansee = !item.cansee"
                v-if="!item.cansee"
                style="width: 20px; height: 12px; margin-left: 8px"
                src="@/assets/images/my_eye.png"
                alt=""
              />
              <img
                @click="item.cansee = !item.cansee"
                v-if="item.cansee"
                style="width: 20px; height: 12px; margin-left: 8px"
                src="@/assets/images/my_eye2.png"
                alt=""
              />
            </div>
            <span class="cardtitle4" @click="goDetail(1, item.pkAccountId)">{{
              $t("MY_WAL_2")
            }}</span>
          </div>

          <div class="cardtitle2">
            {{ `${$t("MY_WAL_7")}(${userInfo.currencyIcon})` }}
          </div>
          <!-- // item.cansee ? `${item.balance|numberToCurrency}` : "****" -->
          <span class="cardtitle3">
            <template v-if="item.cansee">{{
              item.balance | numberToCurrency
            }}</template>
            <template v-else>****</template>
          </span>

          <!-- </div> -->
          <div class="cardtitle5">
            <div class="content">
              <div>{{ `${$t("MY_WAL_8")}(${userInfo.currencyIcon})` }}</div>
              <div class="t1">
                <template v-if="item.cansee">
                  {{ item.availableBalance | numberToCurrency }}</template
                >
                <template v-else>****</template>
              </div>
            </div>
            <div class="content">
              <div>
                {{ `${$t("MY_WAL_9")}(${userInfo.currencyIcon})` }}
                <el-tooltip
                  :content="`${$t('MY_WAL_10')}：${item.lockAccount}\n${$t(
                    'MY_WAL_11'
                  )}：${item.transferAccount}\n${$t('MY_WAL_12')}：${
                    item.withdrawAccount
                  }`"
                  placement="top"
                  effect="light"
                >
                  <i
                    style="cursor: pointer"
                    class="el-icon-warning sizet"
                    size="16px"
                  ></i>
                </el-tooltip>
              </div>
              <div class="t1">
                <template v-if="item.cansee">
                  {{ item.unavailableBalance | numberToCurrency }}</template
                >
                <template v-else>****</template>

                <!-- {{ item.cansee ? `${item.unavailableBalance|numberToCurrency}` : "****" }} -->
              </div>
            </div>
            <div v-if="item.accountType == 4">
              <div>{{ `${$t("MY_WAL_13")}(${userInfo.currencyIcon})` }}</div>
              <div class="t1">
                <template v-if="item.cansee">
                  {{ item.withdrawBalance | numberToCurrency }}</template
                >
                <template v-else>****</template>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <Pay
      :isPay="czShow"
      @closePay="closePay"
      :paramsPost="paramsPost"
      :ifcz="true"
    ></Pay>
    <!-- 转账 -->
    <el-dialog
      :title="$t('MY_WAL_6')"
      :visible.sync="zzShow"
      width="30%"
      center
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div class="diaBox2">
        <el-form
          :model="zzform"
          :rules="zzrules"
          label-width="auto"
          ref="zzform"
        >
          <el-form-item :label="$t('MY_WAL_14')" prop="pkAccount">
            <el-select
              @change="checkYue"
              v-model="zzform.pkAccount"
              :placeholder="$t('CK_KS_38')"
            >
              <el-option
                v-for="(item, index) in accountList"
                :key="index"
                :label="item.pkTransactionKeyVal"
                :value="item.pkId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item :label="$t('MY_WAL_15')" prop="yue">
            <el-input v-model="zzform.yue" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_WAL_16')" prop="memberCode">
            <el-input v-model="zzform.memberCode" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_WAL_17')" prop="memberName">
            <el-input v-model="zzform.memberName" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_WAL_18')" prop="toMemberCode">
            <el-input
              v-model="zzform.toMemberCode"
              @blur="checkName"
              :placeholder="$t('S_C_70')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_WAL_19')" prop="toMemberName">
            <el-input v-model="zzform.toMemberName" disabled></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_WAL_20')" prop="transferMoney">
            <el-input
              v-model="zzform.transferMoney"
              :placeholder="$t('S_C_70')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_WAL_21')" prop="payPwd">
            <el-input
              v-model="zzform.payPwd"
              type="password"
              :placeholder="$t('S_C_70')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_WAL_22')" prop="remarks">
            <el-input
              v-model="zzform.remarks"
              :placeholder="$t('S_C_70')"
            ></el-input>
          </el-form-item>
        </el-form>
        <div style="text-align: center">
          <el-button class="saveBtn" @click="saveZz">{{
            $t("MY_WAL_23")
          }}</el-button>
          <el-button
            @click="zzShow = false"
            style="width: 117px; font-size: 16px"
            >{{ $t("ENU_P_TYPE0") }}</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 提现 -->
    <el-dialog
      :title="$t('MY_WAL_5')"
      :close-on-click-modal="false"
      :visible.sync="txShow"
      width="30%"
      center
      :before-close="handleClose2"
    >
      <div class="diaBox2">
        <el-form
          :model="txform"
          :rules="txrules"
          label-width="auto"
          ref="txform"
        >
          <el-form-item :label="$t('MY_WAL_24')" prop="pkBank">
            <el-input
              v-if="bankData.cardNumber"
              v-model="bankData.cardNumber"
              disabled
            ></el-input>
            <div
              v-else
              @click="goDetail2(3)"
              style="line-height: 60px; color: red; cursor: pointer"
            >
              {{ $t("MY_WAL_25") }}
            </div>
          </el-form-item>

          <el-form-item :label="$t('MY_WAL_26')" prop="cashAmount">
            <el-input
              v-model="txform.cashAmount"
              :placeholder="$t('S_C_70')"
              type="number"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_WAL_21')" prop="payPwd">
            <el-input
              v-model="txform.payPwd"
              type="password"
              :placeholder="$t('S_C_70')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_WAL_22')" prop="remarks">
            <el-input
              v-model="txform.remarks"
              :placeholder="$t('S_C_70')"
            ></el-input>
          </el-form-item>
          <el-form-item :label="$t('MY_WAL_28')" prop="pkAccount">
            <el-select
              clearable
              v-model="txform.pkAccount"
              :placeholder="$t('CK_KS_38')"
              @change="changeAccount"
            >
              <el-option
                v-for="(item, index) in pkBdAccountList"
                :key="index"
                :label="item.pkTransactionKeyVal"
                :value="item.pkId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="" label-width="100px">
            <div class="linetx">
              <div>{{ `${$t("MY_WAL_13")}(${userInfo.currencyIcon})` }}：</div>
              <div>{{ widthDrwaData.amount || "0.00" }}</div>
            </div>
            <div class="linetx">
              <div>{{ `${$t("MY_WAL_29")}(${userInfo.currencyIcon})` }}：</div>
              <div>{{ widthDrwaData.balance || "0.00" }}</div>
            </div>
            <div class="linetx">
              <div>{{ `${$t("MY_WAL_30")}(${userInfo.currencyIcon})` }}</div>
              <div>{{ widthDrwaData.srviceCharge || "0.00" }}</div>
            </div>
            <div class="linetx">
              <div>{{ `${$t("MY_WAL_31")}(${userInfo.currencyIcon})` }}：</div>
              <div>{{ widthDrwaData.minAmount || "0.00" }}</div>
            </div>
          </el-form-item>
        </el-form>
        <div style="text-align: center">
          <el-button :disabled="canbind" class="saveBtn" @click="saveTx">{{
            $t("MY_WAL_23")
          }}</el-button>
          <el-button
            @click="txShow = false"
            style="width: 117px; font-size: 16px"
            >{{ $t("ENU_P_TYPE0") }}</el-button
          >
        </div>
      </div>
    </el-dialog>

    <!-- 实名认证 -->
    <el-dialog
      :title="$t('MY_WAL_32')"
      :visible.sync="smShow"
      width="40%"
      center
    >
      <div class="diaBox3">
        <el-form
          :model="smform"
          :rules="smrules"
          label-width="auto"
          ref="smform"
        >
          <el-form-item required :label="$t('MY_WAL_33')" prop="accountName">
            <el-input v-model="smform.accountName"></el-input>
          </el-form-item>
          <el-form-item
            class="sexclass"
            required
            :label="$t('MY_WAL_34')"
            prop="sex"
          >
            <el-radio-group v-model="smform.sex">
              <el-radio
                :label="item.value"
                v-for="item in sexList"
                :key="item.value"
                >{{ item.label }}</el-radio
              >
            </el-radio-group>
          </el-form-item>
          <el-form-item required :label="$t('MY_WAL_35')" prop="idType">
            <el-select v-model="smform.idType" :placeholder="$t('CK_KS_38')">
              <el-option
                v-for="(item, index) in cardTypeList"
                :key="index"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item required :label="$t('MY_WAL_36')" prop="idCard">
            <el-input v-model="smform.idCard"></el-input>
          </el-form-item>
          <el-form-item required :label="$t('CK_KS_41')" prop="pkBank">
            <el-select v-model="smform.pkBank" :placeholder="$t('CK_KS_38')">
              <el-option
                v-for="(item, index) in bankCardChioceList"
                :key="index"
                :label="item.bankName"
                :value="item.pkId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item required :label="$t('MY_WAL_24')" prop="cardNumber">
            <el-input v-model="smform.cardNumber"></el-input>
          </el-form-item>
          <el-form-item required :label="$t('CK_KS_43')" prop="subBankName">
            <el-input v-model="smform.subBankName"></el-input>
          </el-form-item>
          <el-form-item required :label="$t('MY_WAL_37')" prop="theaddress">
            <el-cascader
              class="area"
              v-model="smform.theaddress"
              @change="setAddress"
              :options="areaData"
              :props="regionParams"
              :placeholder="$t('CK_KS_38')"
            ></el-cascader>
          </el-form-item>
          <el-form-item required :label="$t('S_C_27')" prop="address">
            <el-input v-model="smform.address"></el-input>
          </el-form-item>
          <el-form-item required :label="$t('w_0052')" prop="phone">
            <el-input v-model="smform.phone"></el-input>
          </el-form-item>
          <el-form-item required :label="$t('w_0043')" prop="verificationCode">
            <el-input
              v-model="smform.verificationCode"
              :placeholder="$t('S_C_70')"
            >
              <span
                class="hqyzm1"
                slot="suffix"
                :class="isSend ? 'hui' : ''"
                @click="startTime"
                >{{ getCode }}</span
              >
            </el-input>
          </el-form-item>

          <el-form-item required :label="$t('MY_WAL_39')" prop="idFront">
            <ImageUpload
              style=""
              :backimg="require('../assets/images/markerSpace/idCard-img1.png')"
              v-model="smform.idFront"
            />
          </el-form-item>
          <el-form-item required :label="$t('MY_WAL_40')" prop="idBack">
            <ImageUpload
              :backimg="require('../assets/images/markerSpace/idCard-img2.png')"
              v-model="smform.idBack"
            />
          </el-form-item>
          <div style="text-align: center">
            <el-button class="saveBtn" @click="saveSm('smform')">{{
              $t("MY_WAL_23")
            }}</el-button>
            <el-button
              @click="smShow = false"
              style="width: 117px; font-size: 16px"
              >{{ $t("ENU_P_TYPE0") }}</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>

    <!-- 上传营业执照 -->
    <el-dialog
      :title="$t('CK_KS_56')"
      :visible.sync="yyzzShow"
      width="40%"
      center
    >
      <div class="diaBox3">
        <el-form
          :model="yyzzform"
          :rules="yyzzrules"
          label-width="auto"
          ref="yyzzform"
        >
          <el-form-item
            v-if="ifupYy || yyzzform.approveStatus == 4"
            required
            :label="$t('MN_F_11')"
            prop="approveStatusVal"
          >
            <el-input
              :disabled="true"
              v-model="yyzzform.approveStatusVal"
            ></el-input>
          </el-form-item>
          <div class="disFlex">
            <el-form-item
              v-if="ifupYy || yyzzform.approveStatus == 4"
              :label="$t('CK_KS_19')"
            >
              <el-popover placement="bottom" width="400" trigger="hover">
                <div v-html="yyzzform.rejectMsg"></div>
                <div class="ckxq" slot="reference">查看详情</div>
              </el-popover>
            </el-form-item>
          </div>
          <el-form-item required :label="$t('CK_KS_32')" prop="licenseName">
            <el-input
              :disabled="ifupYy"
              maxlength="100"
              v-model="yyzzform.licenseName"
            ></el-input>
          </el-form-item>
          <el-form-item required :label="$t('CK_KS_33')" prop="creditCode">
            <el-input
              :disabled="ifupYy"
              maxlength="100"
              v-model="yyzzform.creditCode"
            ></el-input>
          </el-form-item>
          <el-form-item required :label="$t('CK_KS_34')" prop="legalName">
            <el-input
              :disabled="ifupYy"
              v-model="yyzzform.legalName"
            ></el-input>
          </el-form-item>

          <el-form-item required :label="$t('MY_WAL_37')" prop="theaddress">
            <el-cascader
              :disabled="ifupYy"
              class="area"
              v-model="yyzzform.theaddress"
              @change="setAddress2"
              :options="areaData"
              :props="regionParams"
              :placeholder="$t('CK_KS_38')"
            ></el-cascader>
          </el-form-item>
          <el-form-item required :label="$t('S_C_27')" prop="licenseAddress">
            <el-input
              :disabled="ifupYy"
              maxlength="200"
              v-model="yyzzform.licenseAddress"
            ></el-input>
          </el-form-item>

          <el-form-item required :label="$t('CK_KS_56')" prop="licenseImage">
            <ImageUpload
              :ifdisabled="ifupYy"
              style=""
              :backimg="require('../assets/images/upload.png')"
              v-model="yyzzform.licenseImage"
            />
          </el-form-item>

          <div style="text-align: center">
            <el-button
              :disabled="ifupYy"
              class="saveBtn"
              @click="saveYyzz('yyzzform')"
              >{{ $t("MY_WAL_23") }}</el-button
            >
            <el-button
              @click="yyzzShow = false"
              style="width: 117px; font-size: 16px"
              >{{ $t("ENU_P_TYPE0") }}</el-button
            >
          </div>
        </el-form>
      </div>
    </el-dialog>
  </div>
</template>
        
<script>
import Pay from "@/components/Pay.vue";
import ImageUpload from "@/components/ImageUpload/index.vue";
import { mapGetters } from "vuex";

import * as wal from "@/api/wallet.js";
import * as api from "@/api/register";
export default {
  name: "walletBalance",
  components: { Pay, ImageUpload },
  data() {
    return {
      isSend: false,
      beginTime: 60,
      getCode: this.$t("w_0055"),
      thetime: "",
      tableData: {},
      total: 0,
      czShow: false,
      paramsPost: "",
      zzShow: false,
      zzform: {
        pkAccount: "", //转出账户
        memberCode: "", //转出账号
        toMemberCode: "", //转入账号
        transferMoney: "", //金额
        remarks: "", //备注
        payPwd: "", //二级密码
        toMemberName: "",
        yue: "",
      },
      accountList: [],
      txform: {
        pkBank: "",
        cashAmount: "",
        remarks: "",
        payPwd: "",
        pkAccount: "",
      },
      txShow: false,
      txrules: {},
      pkBdAccountList: [],
      bankData: {},
      zzrules: [],
      yyzzShow: false,
      yyzzform: {
        theaddress: [],
      },
      yyzzrules: {
        licenseName: [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
        ],
        creditCode: [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
        ],
        legalName: [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
        ],

        theaddress: [
          { required: true, message: this.$t("CK_KS_38"), trigger: "change" },
        ],
        licenseAddress: [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
        ],
        licenseImage: [
          { required: true, message: this.$t("CK_KS_79"), trigger: "change" },
        ],
      },
      smShow: false,
      smform: {},
      smrules: {
        accountName: [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
        ],
        sex: [
          { required: true, message: this.$t("CK_KS_38"), trigger: "change" },
        ],
        idType: [
          { required: true, message: this.$t("CK_KS_38"), trigger: "change" },
        ],
        idCard: [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
        ],
        pkBank: [
          { required: true, message: this.$t("CK_KS_38"), trigger: "change" },
        ],
        cardNumber: [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
        ],
        subBankName: [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
        ],
        theaddress: [
          { required: true, message: this.$t("CK_KS_38"), trigger: "change" },
        ],
        address: [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
        ],
        phone: [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
        ],
        verificationCode: [
          { required: true, message: this.$t("S_C_70"), trigger: "blur" },
        ],
        idFront: [
          { required: true, message: this.$t("MY_WAL_42"), trigger: "change" },
        ],
        idBack: [
          { required: true, message: this.$t("MY_WAL_43"), trigger: "change" },
        ],
      },
      cardTypeList: [], //证件类型
      sexList: [
        { label: this.$t("MY_WAL_44"), value: 1 },
        { label: this.$t("MY_WAL_45"), value: 2 },
      ],
      areaData: [],
      regionParams: {
        label: "name",
        value: "id",
        children: "children",
        expandTrigger: "hover",
      },
      bankCardChioceList: [],
      widthDrwaData: {},
      ifupYy: false,
      canbind: false,
      ifshowBtn: false,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {
    $route(n, o) {
      this.specialArea = 4;
    },
    "txform.cashAmount": "changeAccount",
  },
  created() {},
  mounted() {
    this.specialArea = 4;
   

    this.research();
    this.getOthers();
    this.getAllArea();
    this.ifCodeName();
    this.zzMethods()
   
  },
  methods: {
    zzMethods(){
      if(this.userInfo.serviceCode){
        this.zzform.memberCode = this.userInfo.serviceCode;
      }else{
        this.zzform.memberCode = this.userInfo.memberCode;
      }
      
      wal
      .getTransferChineseName({ memberCode: this.zzform.memberCode })
      .then((res) => {
        if (res.code == 200) {
          this.zzform.memberName = res.memberName;
        }
      });
    },
    ifCodeName() {
      if (this.userInfo.memberCode == "CN68880628") {
        this.ifshowBtn = true;
      } else {
        this.ifshowBtn = false;
      }
    },
    //选择账户
    changeAccount() {
      wal
        .getWidthdrawShow({
          pkAccount: this.txform.pkAccount,
          cashAmount: this.txform.cashAmount || 0,
        })
        .then((res) => {
          this.widthDrwaData = res.data;
        });
    },
    checkYue(val) {
      wal.getTransferMember({ pkAccount: val }).then((res) => {
        this.zzform.yue = res.data;
      });
    },
    saveYyzz(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          if (this.yyzzform.approveStatus == 4) {
            wal.updateLicense(this.yyzzform).then((res) => {
              if (res.code == 200) {
                this.yyzzShow = false;
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              }
            });
          } else {
            wal.addLicense(this.yyzzform).then((res) => {
              if (res.code == 200) {
                this.yyzzShow = false;
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              }
            });
          }
        } else {
          return false;
        }
      });
    },
    saveSm(form) {
      this.$refs[form].validate((valid) => {
        if (valid) {
          wal
            .addAuthentication(this.smform)
            .then((res) => {
              if (res.code == 200) {
                this.smShow = false;
                this.$message({
                  message: res.msg,
                  type: "success",
                });
              }
            })
            .catch((err) => {
              this.beginTime = 0;
            });
        } else {
          return false;
        }
      });
    },
    startTime() {
      if (!this.smform.phone) {
        this.$message({
          message: this.$t("MY_WAL_47"),
          type: "warning",
        });
        return;
      } else {
        if (this.isSend) return;
        this.isSend = true;
        this.getCode = this.beginTime + this.$t("w_0058");
        this.timer = setInterval(() => {
          this.beginTime--;
          this.getCode = this.beginTime + this.$t("w_0058");
          if (this.beginTime < 0) {
            clearInterval(this.timer);
            this.getCode = this.$t("w_0055");
            this.beginTime = 60;
            this.isSend = false;
          }
        }, 1000);
        wal.getVerification({ phone: this.smform.phone }).then((res) => {
          this.$message({
            message: this.$t("MY_WAL_49"),
            type: "success",
          });
        });
      }
    },
    setAddress(res) {
      this.smform.pkProvince = res[0];
      this.smform.pkCity = res[1];
      this.smform.pkCounty = res[2];
    },
    setAddress2(res) {
      this.yyzzform.licenseProvince = res[0];
      this.yyzzform.licenseCity = res[1];
      this.yyzzform.licenseCounty = res[2];
    },
    getAllArea() {
      api.allArea().then((res) => {
        this.areaData = res.data;
      });
    },
    //判断是否进行营业执照上传
    checkExit(index) {
      wal.getIsExist({ businessModule: index }).then((res) => {
        if (res.code == 200 && res.flag == "Y") {
          //用户已实名
          if (index == 1) {
            //提现
            this.txShow = true;
            this.changeAccount();
          } else if (index == 2) {
            //转账
            this.zzShow = true;
          }
        } else if (res.code == 200 && res.flag == "N") {
          //提示进行经销商认证并跳转

          this.$confirm(this.$t("w_1030"), this.$t("w_0034"), {
            confirmButtonText: this.$t("w_0035"),
            cancelButtonText: this.$t("ENU_P_TYPE0"),
            type: "warning",
          }).then(() => {
            this.$router.push({
              path: "/vipCenter",
              query: { id: 5, atabActiveId: 4 },
            });
          });

          // wal.getLicenseDetail().then((res) => {
          //   if (res.code == 200) {
          //     if (res.data.approveStatus) {
          //       this.yyzzform = res.data
          //       this.yyzzform.theaddress = [
          //         res.data.licenseProvince + '',
          //         res.data.licenseCity + '',
          //         (res.data.licenseCounty || '') + '',
          //       ]
          //       this.yyzzform.theaddress = this.yyzzform.theaddress.filter(
          //         (s) => {
          //           return s && s.trim()
          //         }
          //       )
          //       if (this.yyzzform.approveStatus == 4) {
          //         //上传后被驳回时 可修改
          //         this.$message({
          //           message: '请重新上传营业执照',
          //           type: 'warning',
          //         })
          //         this.ifupYy = false
          //       } else {
          //         //上传后未被驳回 不可修改 只能查看
          //         this.ifupYy = true
          //       }
          //       this.yyzzShow = true
          //     } else {
          //       //未上传
          //       this.ifupYy = false
          //       this.yyzzShow = true
          //     }
          //   }
          // })
        }
      });
    },
    //判断是否实名认证
    checkReal(index) {
      //国内必须进行实名认证
      if (this.userInfo.pkCountry == 1) {
        if (this.userInfo.pkGradeId == 1 && index == 1) {
          //结算等级为1直接提示
          this.$message({
            message: "暂时无法提现，请先升级",
            type: "warning",
          });
        } else {
          wal.getIfAuth({ businessModule: index }).then((res) => {
            if (res.code == 200 && res.flag == "Y") {
              this.checkExit(index);
            } else if (res.code == 200 && res.flag == "N") {
              // this.smShow = true;
              this.$router.push({
                path: "/vipCenter",
                query: { id: 5, atabActiveId: 9 },
              });
              this.$message({
                message: this.$t("MY_WAL_50"),
                type: "warning",
              });
            }
          });
        }
      } else {
        //国外直接走提现/转账
        if (index == 1) {
          //提现
          this.txShow = true;
        } else if (index == 2) {
          //转账
          this.zzShow = true;
        }
      }
    },

    //提现
    saveTx() {
      if (!this.txform.cashAmount || this.txform.cashAmount <= 0) {
        this.$message({
          message: this.$t("MY_WAL_46"),
          type: "warning",
        });
        return;
      } else {
        wal.addWithdraw(this.txform).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.txShow = false;
          }
        });
      }
    },
    //转账
    saveZz() {
      wal.addTransfer(this.zzform).then((res) => {
        if (res.code == 200) {
          this.$message({
            message: res.msg,
            type: "success",
          });
          this.zzShow = false;
        }
      });
    },
    checkName() {
      wal
        .getTransferChineseName({ memberCode: this.zzform.toMemberCode })
        .then((res) => {
          if (res.code == 200) {
            this.zzform.toMemberName = res.memberName;
          }
        });
    },
    //获取转账转出账户
    getOthers() {
      wal.getTransferAccount().then((res) => {
        this.accountList = res.data;
      });
      //证件类型
      wal.getCardType().then((res) => {
        this.cardTypeList = res.data;
      });
      wal.getBankCardChoiceList().then((res) => {
        this.bankCardChioceList = res.data;
      });
      wal.getDefaultBank().then((res) => {
        this.bankData = res.data;
        this.txform.pkBank = res.data.pkId;
      });
      wal.getPkBdAccountList({ accountProperty: 3 }).then((res) => {
        this.pkBdAccountList = res.data;
        this.txform.pkAccount = res.data[0].pkId;
      });
      //提现校验是否绑定银行卡
      wal.checkIsbindBank().then((res) => {
        if (res.flag == "Y") {
          this.canbind = false;
        } else if (res.flag == "N") {
          this.canbind = true;
        }
      });
    },
    handleClose() {
      this.zzShow = false;
    },
    handleClose2() {
      this.txShow = false;
    },
    closePay(val) {
      if (val) {
        this.czShow = false;
        this.getDataList();
      } else {
        this.$confirm(this.$t("MY_WAL_51"), "", {
          confirmButtonText: this.$t("w_0035"),
          cancelButtonText: this.$t("ENU_P_TYPE0"),
          type: "warning",
        })
          .then((_) => {
            this.czShow = false;
            // this.$router.replace({
            //   path: "/order/myOrder",
            // });
          })
          .catch((_) => {});
      }
    },
    goDetail(id, pkAccountId) {
      // console.log("点击");
      this.$emit("handleLink", id, pkAccountId);
    },
    goDetail2(id) {
      this.$emit("handleLink", id);
    },
    chongzhi() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 50,
        startDate: "",
        endDate: "",
        assessType: 1,
        changeType: "",
      };
    },
    research() {
      wal.getWalletBalance(this.queryParams).then((res) => {
        res.data.memberWalletBalanceVOList.forEach((element) => {
          element.cansee = false;
        });
        this.tableData = res.data;
      });
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>
        
 <style lang="scss" scoped>
.linetx {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 18px;
}
::v-deep .sexclass .el-radio-group {
  line-height: 65px;
}
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}
::v-deep .el-dialog__header {
  font-size: 32px;
  font-weight: 500;
}
::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
::v-deep .diaBox3 {
  padding: 0 140px;
  .el-form-item {
    line-height: 60px;
  }
  .el-form-item__label {
    font-size: 18px;
    font-weight: 500;
  }
  .el-input__inner {
    width: 290px;
    height: 60px;
    padding: 0 20px;
    font-size: 16px;
  }
  .el-textarea__inner {
    width: 290px;
    height: 60px;
    padding: 0 20px;
    font-size: 16px;
  }
  .saveBtn {
    width: 117px;
    font-size: 16px;
    background-color: rgba(214, 24, 32, 1);
    color: #ffffff;
  }
}
::v-deep .diaBox2 {
  padding: 0 70px;
  .el-form-item {
    line-height: 60px;
  }
  .el-form-item__label {
    font-size: 18px;
    font-weight: 500;
  }
  .el-input__inner {
    width: 290px;
    height: 60px;
    padding: 0 20px;
    font-size: 16px;
  }
  .el-textarea__inner {
    width: 290px;
    height: 60px;
    padding: 0 20px;
    font-size: 16px;
  }
  .saveBtn {
    width: 117px;
    font-size: 16px;
    background-color: rgba(214, 24, 32, 1);
    color: #ffffff;
  }
}
.hqyzm1 {
  // background: #d5251d;
  // border-radius: 8px;
  // padding: 0 7px;
  color: #d5251d;
  line-height: 65px;
  padding-right: 20px;
  font-size: 10px;
  cursor: pointer;
}
.hui {
  opacity: 0.5;
}
.contain {
  background: rgba(249, 249, 249, 1);
  height: 100vh;
  display: flex;

  .topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 0px 0;
    padding: 20px;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .topbtn {
      border: 1px solid #cccccc;
      border-radius: 10px;
      // background: red;
    }
    .topbtn:hover,
    .topbtn:active,
    .topbtn.is-active,
    .topbtn:focus {
      background-color: rgba(192, 31, 31, 1);
      color: #ffffff;
    }
  }
  .tablebox {
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    padding: 10px 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    margin-top: 20px;
    .card {
      width: 300px;
      border-radius: 10px;
      // background: rgba(255, 193, 160, 1);
      background-image: url(../assets/images/yueCard.png);
      background-size: 100% 100%;
      margin: 12px;
      padding: 10px;
      .cardtitle {
        font-size: 18px;
        color: #333333;
        margin: 10px 0;
      }
      .cardtitle2 {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
      }
      .cardflex {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .cardtitle3 {
        margin-top: 10px;
        font-size: 32px;
        font-weight: 500;
        margin-right: 10px;
      }
      .cardtitle4 {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #1d44d5;
        cursor: pointer;
      }
      .cardtitle5 {
        font-size: 12px;
        font-family: PingFang SC-Regular, PingFang SC;
        font-weight: 400;
        color: #333333;
        margin-top: 30px;
        display: flex;
        .content {
          margin-right: 20px;
        }
        .sizet {
          font-size: 14px;
          margin-left: 5px;
        }
        .t1 {
          font-size: 14px;
          font-family: PingFang SC-Regular, PingFang SC;
          font-weight: 400;
          color: #333333;
          margin-top: 10px;
        }
      }
      .cardflex2 {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 5px;

        .t2 {
          margin-right: 10px;
          cursor: pointer;
        }
        .t3 {
          cursor: pointer;
        }
      }
    }
  }
}
.disFlex {
  ::v-deep .el-form-item {
    display: flex;
    align-items: center;
  }
  ::v-deep .el-form-item__content {
    margin-left: 0 !important;
  }
}
.ckxq {
  vertical-align: middle;
  color: #b42b2a;
  cursor: pointer;
}
</style>