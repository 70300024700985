<!--
 * @Descripttion:
 * @version:
 * @Author: kBank
 * @Date: 2023-03-04 09:57:47
-->
<template>
    <div class="contain">
      <div class="content" v-if="goodsList.length>0">
          <div class="list" v-for="(item,index) in goodsList">
            <div class="banner">
              <div class="img">
                  <img src="@/assets/images/yueCard.png" />
              </div>
              <div class="texts">
                <div class="title">[{{item.actTypeVal}}]{{ item.actName }}</div>
                <div class="time">{{ $t('N_I_129') }}：{{item.actStartDate}}-{{item.actEndDate}}</div>
                <div class="lines"></div>
                <div class="goods-text">{{ item.actExplain }}</div>
               <div style="display: flex;justify-content: center;padding: 0 70px">
                   <div class="button-n" v-if="item.existNewPeopleGift==0">{{ $t('w_0205') }}</div>
                   <div class="button-n" v-if="item.existDrawNum==0" style="background: #48B2FD">{{ $t('w_0206') }}</div>
               </div>
              </div>
          </div>
              <div class="button">{{item.actStatusVal}}</div>
<!--              <div class="submit">抽奖</div>-->
          </div>
      </div>
    </div>
</template>
<script>
import * as api from '@/api/activity.js'
import { mapGetters } from 'vuex'
export default {
    components: {
    },
    data() {
        return {
            openDiag: false,
            creationTime: [],
            goodsList: [],
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 50,
            },
        }
    },
    computed: {
        ...mapGetters(['userInfo']),
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        getDataList() {
            api
                .getActivity(Object.assign({}, this.queryParams, this.select))
                .then((res) => {
                    this.goodsList = res.rows
                    this.total = res.total
                })
        },
    },
}
</script>
<style lang="scss" scoped>
.submit{
  width: 86px;
  height: 42px;
  background: #D61820;
  border-radius: 8px 0px 0px 8px;
  right: 0;
  top: 180px;
  position: absolute;
  z-index: 1;
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 42px;
}
.goods-text{
  color: #333;
  font-size: 12px;
  margin: 10px 0 0 20px;
}
.button-n{
  width: 124px;
  height: 34px;
  background: #D61820;
  border-radius: 29px 29px 29px 29px;
  text-align: center;
  line-height: 34px;
  color: #fff;
  font-size: 12px;
  margin: 20px auto 0 auto ;
  cursor: pointer;
}
.lines{
  height: 1px;
  opacity: 0.0451;
  background: #000;
  //border: 1px solid #000000;
}
.title{
  color: #B42B2A;
  font-size: 18px;
  margin-left: 20px;
}
.time{
  color: #999;
  font-size: 12px;
  margin: 10px 10px 10px 20px;
  //margin-left: 20px;
}
.texts{
  //height: 137px;
  padding: 20px 0 30px 0;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px 0px rgba(204,204,204,0.5);
  margin-top: -3px;
  border-radius: 0 0 10px 10px ;
}
.content{
  display: flex;
}
.button{
  width: 86px;
  height: 42px;
  background: linear-gradient(180deg, #7692FF 0%, #335AF1 100%);
  border-radius: 8px 0px 0px 8px;
  right: 0;
  top: 20px;
  position: absolute;
  z-index: 1;
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 42px;
}
.contain {
  margin-top: 20px;
  background: #fff;
  //height: 470px;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px 0px rgba(135,135,135,0.3);
  //border-radius: 10px 10px 10px 10px;
  padding: 40px 0;
  .list{
    height: 470px;
    position: relative;
    margin-left: 40px;
    .banner{
      img{
        width: 432px;
        height: 253px;
        border-radius: 10px 10px 0 0 ;
      }
      width: 432px;
      height: 253px;
      border-radius: 10px 10px 0 0;
      position: relative;
    }
  }
}
</style>