<template>
  <div class="contain">
    <div class="rightbox" style="width: 100%">
      <div class="tablebox">
        <el-table
          :data="tableData"
          :header-cell-style="{ background: '#EEEEEE' }"
          :row-class-name="tableRowClassName"
        >
          <el-table-column
            align="center"
            prop="pkAccountVal"
            :label="$t('MN_F_T_535')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="cashAmount"
            :label="`${$t('MY_WAL_26')}(${userInfo.currencyIcon})`"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.cashAmount | toThousandthAndKeepDecimal
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="serviceCharge"
            :label="`${$t('MN_F_T_553')}(${userInfo.currencyIcon})`"
          >
            <template slot-scope="scope">
              <span>{{
                scope.row.serviceCharge | toThousandthAndKeepDecimal
              }}</span>
            </template></el-table-column
          >
          <el-table-column
            align="center"
            prop="incomeTax"
            :label="`${$t('MN_F_T_554')}(${userInfo.currencyIcon})`"
            ><template slot-scope="scope">
              <span>{{
                scope.row.incomeTax | toThousandthAndKeepDecimal
              }}</span>
            </template></el-table-column
          >
          <el-table-column
            align="center"
            prop="issuedAmount"
            :label="`${$t('MN_F_T_555')}(${userInfo.currencyIcon})`"
            ><template slot-scope="scope">
              <span>{{
                scope.row.issuedAmount | toThousandthAndKeepDecimal
              }}</span>
            </template></el-table-column
          >
          <el-table-column
            align="center"
            prop="bankName"
            :label="$t('CK_KS_41')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="cardNumber"
            min-width="150px"
            :label="$t('MN_F_T_538')"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="accountName"
            :label="$t('MN_F_T_539')"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="approveStateVal"
            :label="$t('MY_CK_7')"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="creationTime"
            :label="$t('MN_F_T_707')"
            min-width="150px"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="remarks"
            :label="$t('MY_WAL_22')"
          >
          </el-table-column>
          <el-table-column
            align="center"
            prop="statusVal"
            :label="$t('MN_F_T_474')"
          >
          </el-table-column>

          <el-table-column align="center" prop="" :label="$t('MY_ORD_14')">
            <template slot-scope="scope">
              <el-button
                v-if="scope.row.status == 0 && scope.row.approveState == 1"
                style="color: red"
                @click="revoke(scope.row)"
                type="text"
                size="small"
                >{{ $t("MN_F_50") }}</el-button
              >
            </template>
          </el-table-column>
        </el-table>
        <pagination
          :total="total"
          :page.sync="queryParams.pageNum"
          :limit.sync="queryParams.pageSize"
          @pagination="research"
        />
      </div>
    </div>
  </div>
</template>
            
    <script>
import pagination from "@/components/pagination.vue";
import { mapGetters } from "vuex";
import { getWithdrawList, cancelWithdraw } from "@/api/wallet.js";
export default {
  name: "walletBalance",
  components: {
    pagination,
  },
  computed: {
    ...mapGetters(["userInfo", "specialCarNum"]),
  },
  data() {
    return {
      tableData: [],
      total: 0,
      queryParams: {
        pageNum: 1,
        pageSize: 50,
      },
    };
  },
  watch: {
    $route(n, o) {
      this.specialArea = 4;
    },
  },
  created() {},
  mounted() {
    this.specialArea = 4;
    this.research();
  },
  methods: {
    //撤销
    revoke(row) {
      this.$confirm(this.$t("MN_F_T_912") + "?").then((_) => {
        cancelWithdraw({ pkId: row.pkId }).then((res) => {
          if (res.code == 200) {
            this.$message({
              message: res.msg,
              type: "success",
            });
            this.research();
          }
        });
      });
    },
    research() {
      getWithdrawList(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },

    tableRowClassName({ row, rowIndex }) {
      if (rowIndex % 2 == 1) {
        return "warning-row";
      } else if (rowIndex % 2 == 0) {
        return "success-row";
      }
      return "";
    },
  },
};
</script>
            
<style lang="scss" scoped>
::v-deep .el-table .warning-row {
  background: #f9f9f9;
}

::v-deep .el-table .success-row {
  background: #ffffff;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
.contain {
  background: rgba(249, 249, 249, 1);
  height: 100vh;
  display: flex;
  .goodArea {
    display: flex;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    color: #333333;
    width: 100%;
    height: 80px;
    border-bottom: 5px solid rgba(0, 0, 0, 0.05);
    .goodArea_i {
      margin: 0 35px;
      text-align: center;
      cursor: pointer;
    }
    .actArea {
      color: #b42b2a;
    }
  }
  .topbox {
    // height: 109px;
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    margin: 20px 0 40px 0;
    padding: 30px 16px 10px 16px;
    .searchbox {
      display: flex;
      align-items: center;
      .btn1 {
        background: #cccccc;
        border-radius: 5px 5px 5px 5px;
        color: #ffffff;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
      }
    }
  }
  .rightbox {
    margin-top: 30px;
  }
  .tablebox {
    box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
    border-radius: 10px 10px 10px 10px;
    .tabletop {
      text-align: right;
      padding: 20px;
      .tabletoptitle1 {
        font-size: 18px;
      }
      .tabletoptitle2 {
        font-size: 24px;
        font-weight: 600;
      }
    }
  }
}
</style>