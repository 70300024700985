/*
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-03-04 10:21:09
 */
import request from '@/util/request'

// 订单类型
export function orderType(params) {
  return request({
    url: '/sale/api/my-order/order-type',
    method: 'get',
    params
  })
}
// 订单列表
export function orderList(params) {
  return request({
    url: '/sale/api/my-order/list',
    method: 'get',
    params
    // params: qs.stringify(params, {arrayFormat: 'brackets'})
  })
}
// 订单状态
export function orderStatus(params) {
  return request({
    url: '/system/pub/enums/order-status-api',
    method: 'get',
    params
  })
}
// 订单数量
export function orderNum(params) {
  return request({
    url: '/sale/api/my-order/num',
    method: 'get',
    params
  })
}
// 自助撤单列表
export function selfRevokeList(params) {
  return request({
    url: '/sale/api/my-order/selfRevokeList',
    method: 'get',
    params
  })
}
// 自助撤单参数校验
export function selfRevokeListCheck(data) {
  return request({
    url: '/sale/api/my-order/check-self-revoke-order',
    method: 'post',
    data
  })
}
// 自助撤单
export function selfRevokeOrder(data) {
  return request({
    url: '/sale/api/my-order/self-revoke-order',
    method: 'post',
    data
  })
}
// 订单取消
export function cancelOrder(data) {
  return request({
    url: '/sale/api/my-order/cancel/' + data,
    method: 'delete',
  })
}
// 物流状态
export function expressList(id) {
  return request({
    url: '/sale/api/my-order/express/' + id,
    method: 'get',
  })
}
// 校验订单状态
export function payCheck(id) {
  return request({
    url: '/sale/api/my-order/pay-check/' + id,
    method: 'get',
  })
}