<template>
  <div class="contain">
    <template v-if="atab == 1">
      <div class="kuang">
        <el-form ref="queryParams" :model="queryParams" label-width="100px">
          <el-row>
            <el-col :span="6">
              <el-form-item :label="$t('MY_CK_37')">
                <el-date-picker
                  v-model="queryParams.month"
                  type="month"
                  value-format="yyyy-MM"
                  :placeholder="$t('CK_KS_38')"
                >
                </el-date-picker>
              </el-form-item>
            </el-col>
            <el-col :span="4" style="margin-left: 20px">
              <div class="searchbox">
                <el-button class="btn2" @click="getDataList">{{ $t('N_I_183') }}</el-button>
                <el-button class="btn1" @click="reset">{{ $t('CK_KS_6') }}</el-button>
              </div>

            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tablebox">
        <el-table
          :data="tableData"
          :header-cell-style="{ background: '#EEEEEE' }"
          height="1000px"
        >
          <el-table-column align="center" prop="month" :label="$t('MY_CK_37')">
            <template slot-scope="scope">
              <span  >{{
                scope.row.month
              }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="asumPv"
            :label="$t('w_0463') + '(PV)'"
          >
            <template slot-scope="scope">
              <span class="month_text" @click="getMonth(scope.row,1)"> {{ scope.row.asumPv | decimalFormat }}</span>
            </template>
          </el-table-column>
          <el-table-column
            align="center"
            prop="bsumPv"
            :label="$t('w_0464') + '(PV)'"
          >
            <template slot-scope="scope">
              <span class="month_text" @click="getMonth(scope.row,2)">  {{ scope.row.bsumPv | decimalFormat }}</span>
            
             
            </template>
          </el-table-column>
          <!-- <el-table-column
            align="center"
            prop="repASumPv"
            :label="$t('w_0465') + '(PV)'"
          >
            <template slot-scope="scope">
              <span class="month_text" @click="getMonth(scope.row,1)"> {{ scope.row.repASumPv | decimalFormat }}</span>
            </template>
          </el-table-column> -->
          <!-- <el-table-column
            align="center"
            prop="repBSumPv"
            :label="$t('w_0466') + '(PV)'"
          >
            <template slot-scope="scope">
              <span class="month_text" @click="getMonth(scope.row,2)"> {{ scope.row.repBSumPv | decimalFormat }}</span>
             
            </template>
          </el-table-column> -->
          <el-table-column
            align="center"
            prop="firstAchieve"
            :label="$t('MN_F_T_1003') + '(PV)'"
          >
            <template slot-scope="scope">
              <span class="month_text" @click="getMonth(scope.row,0)"> {{ scope.row.firstAchieve | decimalFormat }}</span>
             
            </template>
          </el-table-column>
          <!-- <el-table-column
            align="center"
            prop="repurchaseAchieve"
            :label="$t('MN_F_T_1004') + '(PV)'"
          >
            <template slot-scope="scope">
              <span class="month_text" @click="getMonth(scope.row,0)">  {{ scope.row.repurchaseAchieve | decimalFormat }}</span>
            
            </template>
          </el-table-column> -->
        </el-table>
      </div>

      <pagination
        v-show="total > 0"
        :total="total"
        :page.sync="queryParams.pageNum"
        :limit.sync="queryParams.pageSize"
        @pagination="getDataList"
      />
    </template>
    <template v-if="atab == 2">
      <div class="kuang">
        <el-form ref="queryParams" :model="queryParams" label-width="100px">
          <el-row>
            <el-col :span="4">
              <el-form-item :label="$t('MN_T_1')">
                <el-input v-model="detailParams.memberCode"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item :label="$t('CK_KS_14')">
                <el-input v-model="detailParams.memberName"></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item :label="$t('PER_DA_5')">
                <el-select
                  v-model="detailParams.pk_grade"
                  :placeholder="$t('CK_KS_38')"
                >
                  <el-option
                    v-for="(item, index) in gradeList"
                    :key="index"
                    :label="item.gradeName"
                    :value="item.pkId"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4">
              <el-form-item :label="$t('CK_KS_16')">
                <el-select
                  v-model="detailParams.pk_awards"
                  :placeholder="$t('CK_KS_38')"
                >
                  <el-option
                    v-for="(item, index) in awardsList"
                    :key="index"
                    :label="item.label"
                    :value="item.value"
                  ></el-option>
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="4" style="margin-left: 20px">
              <div class="searchbox">
                <el-button class="btn2" @click="goDetail">{{ $t('N_I_183') }}</el-button>
                <el-button class="btn1" @click="reset2">{{ $t('CK_KS_6') }}</el-button>
              </div>
             
            </el-col>
          </el-row>
        </el-form>
      </div>
      <div class="tablebox">
        <div style="padding: 10px">
          <el-button
            size="mini"
            type="info"
            style="
              background-color: #eeeeee;
              color: #333333;
              border-radius: 4px 4px 4px 4px;
              opacity: 1;
              border: 1px solid #eeeeee;
            "
            @click="atab = 1"
            >{{ $t("MN_T_4") }}</el-button
          >
        </div>
        <el-table
          :data="detailList"
          :header-cell-style="{ background: '#EEEEEE' }"
        >
          <el-table-column
            align="center"
            prop="memberCode"
            :label="$t('MN_T_1')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="memberName"
            :label="$t('CK_KS_14')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="pkGradeVal"
            :label="$t('PER_DA_5')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="pkAwardsVal"
            :label="$t('CK_KS_16')"
          ></el-table-column>
          <el-table-column
            align="center"
            prop="firstAchieve"
            :label="$t('MN_F_T_857') + '(PV)'"
          >
          <template slot-scope="scope">
              {{ scope.row.firstAchieve | decimalFormat }}
            </template>
        </el-table-column>
          <el-table-column
            align="center"
            prop="repurchaseAchieve"
            :label="$t('MN_F_T_844') + '(PV)'"
          >
          <template slot-scope="scope">
              {{ scope.row.repurchaseAchieve | decimalFormat }}
            </template>
        </el-table-column>
        </el-table>
      </div>

      <pagination
        v-show="total2 > 0"
        :total="total2"
        :page.sync="detailParams.pageNum"
        :limit.sync="detailParams.pageSize"
        @pagination="goDetail"
      />
    </template>
  </div>
</template>
    
    <script>
import * as sel from "@/api/selfservice.js";
import { getGradeList } from "@/api/bonus.js";
import { mapGetters } from 'vuex'
export default {
  components: {},
  filters: {
    decimalFormat(value) {
      return Number(value).toFixed(2);
    },
  },
  data() {
    return {
      creationTime: [],
      // 查询参数
      queryParams: {
        pageNum: 1,
        pageSize: 50,
      },
      total: 0,
      total2: 0,
      // isTab: 0,
      tableData: [],
      atab: 1,
      detailParams: {
        pageNum: 1,
        pageSize: 50,
        month: "",
        placeDept:""
      },
      detailList: [],
      gradeList: [],
      awardsList: [],
      themonth: "",
      placeDept:""
    };
  },
  computed: {
    ...mapGetters(['userInfo']),
  },
  mounted() {
    this.getDataList();
    this.getOthers();
  },
  methods: {
    getMonth(row,index) {
      this.themonth = row.month;
      this.placeDept = index
      this.atab = 2;
      this.goDetail();
    },
    goDetail() {
      this.detailParams.month = this.themonth;
      this.detailParams.placeDept = this.placeDept
      sel.getMonthAchieveDetail(this.detailParams).then((res) => {
        this.detailList = res.rows;
        this.total2 = res.total;
      });
    },

    getDataList() {
      sel.getMonthAchieve(this.queryParams).then((res) => {
        this.tableData = res.rows;
        this.total = res.total;
      });
    },
    getOthers() {
      getGradeList().then((res) => {
        this.gradeList = res.data;
      });
      sel.getAwardsList({
        pkCountry:this.userInfo.pkCountry
      }).then((res) => {
        this.awardsList = res.data;
      });
    },
    reset() {
      this.queryParams = {
        pageNum: 1,
        pageSize: 50,
      };
      this.getDataList();
    },
    reset2() {
      this.detailParams = {
        pageNum: 1,
        pageSize: 50,
      };
      this.goDetail();
    },
  },
};
</script>
    
   <style lang="scss" scoped>
.month_text {
  color: #48b2fd;
  cursor: pointer;
  text-decoration: underline;
}
::v-deep .el-table thead {
  color: #000000;
  font-size: 14px;
}
.tablebox {
  margin-top: 20px;
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
  border-radius: 10px 10px 10px 10px;
  .table_top {
    padding: 20px 0;
    text-align: right;
    font-size: 12px;
    font-family: PingFang SC-Regular, PingFang SC;
    font-weight: 400;
    color: #c8161d;
  }
}
.searchbox {
      display: flex;
      align-items: center;
      .btn1 {
        background: #cccccc;
        border-radius: 5px 5px 5px 5px;
        color: #ffffff;
      }
      .btn2 {
        border-radius: 5px 5px 5px 5px;
        background: rgba(214, 24, 32, 1);
        color: #ffffff;
      }
    }
.local {
  width: 550px;

  // padding: 0px 60px;
  border-radius: 8px;
  border: 1px solid rgba(123, 120, 120, 0.5);
  .local_i {
    display: flex;
    align-items: center;
    // padding: 30px 90px;
    padding-left: 20px;
  }
  .local_a {
    padding-left: 20px;
    background: #eeeeee;
    border-radius: 8px 8px 8px 8px;
  }
  .local1 {
    font-size: 14px;
    font-weight: 400;
    color: #333333;
    margin-right: 20px;
  }
}
::v-deep .el-radio {
  margin-right: 0;
}

.saveBtn1 {
  margin-top: 20px;
  width: 209px;
  height: 48px;
  font-size: 16px;
  background-color: #cccccc;
  color: #ffffff;
}
.saveBtn {
  margin-top: 20px;
  width: 209px;
  height: 48px;
  font-size: 16px;
  background-color: rgba(214, 24, 32, 1);
  color: #ffffff;
}
::v-deep .diaBox {
  .membertitle {
    padding-top: 20px;
    font-weight: 600;
    color: #333333;
    font-size: 14px;
    line-height: 24px;
  }
  .membercontent {
    font-weight: 400;
    color: #333333;
    line-height: 16px;
    line-height: 24px;
  }
  //   padding: 0 60px;
  .el-form-item {
    line-height: 49px;
  }
  .el-form-item__label {
    font-size: 16px;
    font-weight: 500;
  }
  .el-input__inner {
    width: 560px;
    height: 49px;
    padding: 0 20px;
    font-size: 16px;
  }

  .saveBtn {
    margin-top: 20px;
    width: 209px;
    height: 48px;
    font-size: 16px;
    background-color: rgba(214, 24, 32, 1);
    color: #ffffff;
  }
}
.contain {
  padding-top: 20px;

  .kuang {
    background: #ffffff;
    box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
    border-radius: 8px 8px 8px 8px;
    padding-top: 20px;
  }
}
.table {
  background: #ffffff;
  box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
  border-radius: 10px 10px 10px 10px;
  margin-top: 40px;
  font-size: 14px;
  .tabList {
    display: flex;
    align-items: center;
    font-weight: 400;
    color: #666666;
    padding: 20px 20px 0 20px;
    .tab_i {
      margin-right: 70px;
      border-bottom: 5px solid #fff;
      padding-bottom: 10px;
      position: relative;
      cursor: pointer;
      .qiu {
        position: absolute;
        width: 20px;
        height: 20px;
        background: #b42b2a;
        top: -10px;
        right: -10px;
        border-radius: 50%;
        font-size: 12px;
        color: #ffffff;
        text-align: center;
        line-height: 20px;
      }
    }
    .tabAct {
      color: #b42b2a;
      border-bottom: 5px solid #b42b2a;
    }
  }
}
.table_tit {
  background: #eeeeee;
  padding: 30px;
  display: flex;
  font-size: 14px;
  font-weight: 600;
  color: #333333;
}
.table_tit2 {
  width: 8%;
  text-align: center;
}
.t_btm_a {
  display: flex;
  align-items: center;
  padding: 20px 30px;
}
.t_btm_l {
  width: 20%;
}
.t_btm_r {
  // width: 8%;
  text-align: center;
  display: flex;
  flex: 1;
  align-items: center;
}
.table_width {
  text-align: center;
  div {
    flex: 1;
    word-break: break-all;
  }
  // div:nth-child(11) {
  //   width: 8%;
  // }
}
.tab_i1 {
  // padding-left: 30px;
  display: flex;
  align-items: center;
}
.tab_i1_1 {
  // display: flex;
  text-align: left;
  flex: 1;
  padding-left: 10px;
  .prot {
    margin-bottom: 20px;
  }
  .prot_i {
    width: 50% !important;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
.t_top {
  display: flex;
  align-items: center;
  padding: 18px 0 15px 30px;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  span {
    margin-right: 60px;
  }
}
.t_btm {
  display: flex;
  align-items: center;
  // padding: 20px 0px 20px 30px;
  margin-bottom: 10px;
  img {
    width: 64px;
    height: 64px;
    border-radius: 8px 8px 8px 8px;
  }
}
.order_i {
  background: #ffffff;
  box-shadow: 0px 4px 20px 0px rgba(204, 204, 204, 0.5);
  border-radius: 10px 10px 10px 10px;
  margin-bottom: 20px;
}
.del {
  div:nth-child(2) {
    width: 100%;
  }
  div:nth-child(3) {
    width: 100%;
  }
  div:nth-child(1) {
    width: 100%;
    color: #3499ff;
  }
}
.del1 {
  color: #d61820;
  cursor: pointer;
  margin-top: 10px;
}
.del2 {
  color: #ff9008;
  cursor: pointer;
}
.del3 {
  color: #d61820;
  cursor: pointer;
}
.del4 {
  color: #d61820;
  cursor: pointer;
}
::v-deep .el-range-editor.el-input__inner {
  width: auto;
}
::v-deep .el-select {
  width: 100%;
}
</style>