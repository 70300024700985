<!--
 * @Descripttion:
 * @version:
 * @Author: kBank
 * @Date: 2023-03-04 09:57:47
-->
<template>
	<div class="contain orderCenter">
		<div class="kuang">
			<el-form ref="select" :model="select" label-width="80px">
				<el-row>
					<el-col :span="6">
						<el-form-item :label="$t('MY_ORD_1')">
							<el-input v-model="select.orderCode"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item :label="$t('N_I_124')">
							<el-input v-model="select.memberCode"></el-input>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item :label="$t('MY_ORD_2')">
							<el-select :placeholder="$t('CK_KS_38')" v-model="select.orderType">
								<el-option :label="$t('ENU_GIFT_T_1')" value=""></el-option>
								<el-option v-for="item in orderTypes" :key="item.value" :label="item.label"
									:value="item.value"></el-option>
							</el-select>
						</el-form-item>
					</el-col>
					<el-col :span="6">
						<el-form-item :label="$t('MY_ORD_3')">
							<el-date-picker v-model="creationTime" @change="changeTime" value-format="yyyy-MM-dd"
								type="daterange" :range-separator="$t('w_0139')" :start-placeholder="$t('CK_KS_4')"
								:end-placeholder="$t('CK_KS_5')">
							</el-date-picker>
						</el-form-item>
					</el-col>
				</el-row>
				<el-row>
					<el-col :span="4" style="margin-left: 20px">
						<div class="searchbox">
							<el-button class="btn2" @click="getDataList">{{ $t('N_I_183') }}</el-button>
							<el-button class="btn1" @click="reset">{{ $t('CK_KS_6') }}</el-button>
						</div>
					</el-col>
				</el-row>
			</el-form>
		</div>
		<!-- table -->
		<div class="table">
			<div class="tabList">
				<div :class="['tab_i', isTab === '' ? 'tabAct' : '']" @click="isTab = ''">
					{{ $t("ENU_GIFT_T_1") }}
				</div>
				<div :class="['tab_i', isTab === item.value ? 'tabAct' : '']" v-for="item in orderStatusList"
					@click="isTab = item.value" :key="item.value">
					{{ item.label }}
					<div class="qiu" v-show="item.value == 0">{{ waitPayNum }}</div>
					<div class="qiu" v-show="item.value == 1">{{ payNum }}</div>
				</div>
			</div>
			<div class="table_tit">
				<div class="t_btm_l">
					<div class="tab_i1">{{ $t("MY_ORD_5") }}</div>
				</div>
				<div class="t_btm_r table_width">
					<div>{{ $t("MY_ORD_2") }}</div>
					<div>{{ $t("MY_ORD_6") }}</div>
					<!-- <div>订单业绩(PV)</div>
              <div>订单业绩(BV)</div> -->
					<div>{{ $t("MY_ORD_7") }}</div>
					<div>{{ $t("MY_ORD_8") }}</div>
					<div>{{ $t("N_I_124") }}</div>
					<div>{{ $t("CK_KS_14") }}</div>
					<div>{{ $t("MY_ORD_9") }}</div>
					<div>{{ $t("MY_ORD_10") }}</div>
					<span class="sheng">{{ $t("w_0052") }}</span>
					<!-- <span class="sheng">省、市、区/县、详细地址</span> -->
					<div>{{ $t("MY_ORD_11") }}</div>
					<div>{{ $t("MY_ORD_12") }}</div>
					<div>{{ $t("MY_ORD_13") }}</div>
					<div>{{ $t("S_C_27") }}</div>
					<div>{{ $t("MY_ORD_14") }}</div>
				</div>
			</div>
			<div v-for="(item, index) in orderLists" class="order_i" :key="index">
				<div class="t_top">
					<span>{{ $t("MY_ORD_1") }}：{{ item.orderCode }}</span>
					<span>{{ $t("MY_ORD_15") }}：{{ item.creationTime }}</span>
					<span>{{ $t("MY_ORD_3") }}：{{ item.payTime }}</span>
					<span>{{ $t("MY_ORD_16") }}(PV)：{{ item.orderAchieve |toThousandthAndKeepDecimal}}</span>
					<span>{{ $t("MY_ORD_16") }}(BV)：{{ item.orderAssAchieve |toThousandthAndKeepDecimal}}</span>
				</div>
				<div class="t_btm_a">
					<div class="t_btm_l">
						<div v-for="(ctem, cndex) in item.itemList" :key="cndex" class="t_btm">
							<!-- 详情 -->
							<div class="tab_i1">
								<img :src="ctem.cover" alt="" />
								<div class="tab_i1_1">
									<div class="prot">{{ ctem.productName }}</div>
									<div>{{ ctem.specsName }}</div>
									<div class="prot_i">
										<div>{{ ctem.price |toThousandthAndKeepDecimal}}</div>
										<div>X{{ ctem.quantity }}</div>
									</div>
								</div>

							</div>
						</div>
					</div>
					<div class="t_btm_r table_width">
						<!-- 类型 -->
						<div>{{ item.orderTypeVal }}</div>
						<!-- 金额 -->
						<div>
							{{ item.orderAmount |toThousandthAndKeepDecimal}}
						</div>
						<!-- pv -->
						<!-- <div>{{ item.orderAchieve }}</div> -->
						<!-- Bv -->
						<!-- <div>{{ item.orderAssAchieve }}</div> -->
						<!-- 支付编号 -->
						<div>
							{{ item.creatorCode }}
						</div>
						<!-- 支付姓名 -->
						<div>
							{{ item.creatorName }}
						</div>
						<!-- 会员编号 -->
						<div>{{ item.memberCode }}</div>
						<!-- 会员姓名 -->
						<div>{{ item.memberName }}</div>
						<!-- 状态 -->
						<div v-if="item.orderStatus == '4'" style="color: #666">
							{{ item.orderStatusVal }}
						</div>
						<div v-if="item.orderStatus == '1'" style="color: #009b58">
							{{ item.orderStatusVal }}
						</div>
						<div v-if="item.orderStatus == '0'" style="color: #d61820">
							{{ item.orderStatusVal }}
						</div>
						<div v-if="item.orderStatus == '3'" style="color: #ffad41">
							{{ item.orderStatusVal }}
						</div>
						<div v-if="item.orderStatus == '5'" style="color: #ffad41">
							{{ item.orderStatusVal }}
						</div>

						<!-- 名 -->
						<div>{{ item.recName }}</div>
						<!-- 电话 -->
						<span class="sheng">{{ item.recPhone }}</span>
						<!-- 地址 -->
						<div>{{ item.recProvince }}</div>
						<div>{{ item.recCity }}</div>
						<div>{{ item.recCounty || "" }}</div>
						<div>{{ item.address }}</div>
						<!-- <span class="sheng">{{item.recProvince}}{{item.recCity}}{{item.recCounty || ''}}{{ item.address }}</span> -->
						<!-- 操作 -->
						<div v-if="item.orderStatus == 0" class="del">
							<el-popover placement="top-start" title="" width="200" trigger="hover"
								:content="item.remark">
								<div slot="reference" v-if="item.remark" style="cursor: pointer">
									{{ $t("MY_WAL_22") }}
								</div>
							</el-popover>
							<div class="del1" @click="goPay(item)">{{ $t("MY_ORD_17") }}</div>
							<div class="del2" @click="cancelPay(item)">
								{{ $t("MY_ORD_18") }}
							</div>
							<div class="del3" @click="openpRint(item)" v-if="userInfo.pkCountry != 1">{{$t('w_1019')}}
							</div>
						</div>
						<div v-if="item.orderStatus == 1||item.orderStatus == 4" class="del">
							<el-popover placement="top-start" title="" width="200" trigger="hover"
								:content="item.remark">
								<div slot="reference" v-if="item.remark" style="cursor: pointer">
									{{ $t("MY_WAL_22") }}
								</div>
							</el-popover>
							<div class="del3" @click="openpRint(item)" v-if="userInfo.pkCountry != 1">{{$t('w_1019')}}
							</div>
							<!-- <div class="del3">修改地址</div> -->
						</div>
						<div v-if="item.orderStatus == 3" class="del">
							<el-popover placement="top-start" title="" width="200" trigger="hover"
								:content="item.remark">
								<div slot="reference" v-if="item.remark" style="cursor: pointer">
									{{ $t("MY_WAL_22") }}
								</div>
							</el-popover>
							<div class="del4" @click="openWl(item.orderCode)">
								{{ $t("MY_ORD_19") }}
							</div>
							<div class="del3" @click="openpRint(item)" v-if="userInfo.pkCountry != 1">{{$t('w_1019')}}
							</div>
						</div>
						<div v-if="item.orderStatus == 5" class="del">
							<el-popover placement="top-start" title="" width="200" trigger="hover"
								:content="item.remark">
								<div slot="reference" v-if="item.remark">
									{{ $t("MY_WAL_22") }}
								</div>
							</el-popover>
							<div class="del4" @click="openWl(item.orderCode)">
								{{ $t("MY_ORD_19") }}
							</div>
							<div class="del3" @click="openpRint(item)" v-if="userInfo.pkCountry != 1">{{$t('w_1019')}}
							</div>
						</div>

					</div>
				</div>
			</div>
		</div>
		<pagination v-show="total > 0" :total="total" :page.sync="queryParams.pageNum"
			:limit.sync="queryParams.pageSize" @pagination="getDataList" />
		<Pay :isPay="isPay" @closePay="closePay" :onlinePay="onlinePay" :paramsPost="paramsPost"></Pay>
		<el-dialog :title="$t('MY_ORD_19')" :visible.sync="openDiag" :close-on-click-modal="false" width="40%" center>
			<div class="all_dig">
				<!-- {{ wlData }} -->
				<div @click="isClick = index" v-for="(item, index) in wlData" :key="item.expressCode"
					:class="isClick == index ? 'act' : 'tit'">
					{{ $t("MY_ORD_20") }}
				</div>
			</div>
			<div class="head_t">
				<img :src="wlImg" alt="" />
				<div>
					<div>{{ $t("MY_ORD_21") }}：{{ wlData[isClick].expressName }}</div>
					<div>{{ $t("MY_ORD_22") }}：{{ wlData[isClick].expressCode }}</div>
				</div>
			</div>
			<div class="wl_tree" v-for="(ctem, cndex) in wlData[isClick].detailList" :key="cndex">
				<div class="tree_l">{{ ctem.time }}</div>
				<div class="zhong">
					<div class="qiu"></div>
					<div class="gun" v-show="cndex != wlData[isClick].detailList.length - 1"></div>
				</div>
				<div class="tree_r">{{ ctem.context }}</div>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button @click="openDiag = false">{{ $t("MY_ORD_24") }}</el-button>
				<el-button type="primary" @click="openDiag = false">{{ $t("w_0035") }}</el-button>
			</span>
		</el-dialog>
		<!-- {{$t('w_1019')}} -->
		<el-dialog :title="$t('w_1019')" :visible.sync="isPrint" width="50%">
			<div class="print" id="printDom">
				<div class="digTit">INVOICE</div>
				<div class="digCent">
					<div class="digCent_l">
						<div class="digCent_f">
							<div>{{ $t("CK_KS_14") }}</div>
							<div>{{ orderDetailList.memberName }}</div>
						</div>
						<div class="digCent_f">
							<div>{{ $t("MN_T_1") }}</div>
							<div>{{ orderDetailList.memberCode }}</div>
						</div>
						<div class="digCent_f">
							<div>{{ $t("w_0160") }}</div>
							<div>{{ orderDetailList.memberPhone }}</div>
						</div>
						<div class="digCent_f">
							<div>{{ $t("MY_ORD_3") }}</div>
							<div>{{ orderDetailList.payTime }}</div>
						</div>
						<div class="digCent_f">
							<div>{{ $t("MY_ORD_1") }}</div>
							<div>{{ orderDetailList.orderCode }}</div>
						</div>
					</div>
					<div class="digCent_r">
						<div>TO</div>
						<div>
							{{ orderDetailList.recName }}
							{{ orderDetailList.recPhone }}
						</div>
						<div style="width: 400px">
							{{ orderDetailList.recProvince }}{{ orderDetailList.recCity
              }}{{ orderDetailList.address }}
						</div>
					</div>
				</div>
				<!-- <div style="border-top:1px solid #000;margin-top:60px"></div> -->
				<el-table :data="orderDetailList.itemList" style="width: 100%; margin-top: 60px"
					:header-cell-style="{ color: '#000' }">
					<el-table-column prop="productName" align="center" :label="$t('w_0078')"></el-table-column>
					<el-table-column prop="quantity" align="center" :label="$t('MY_TH_2')"></el-table-column>
					<el-table-column prop="jg" align="center" :label="$t('MN_F_T_620')">
						<template slot-scope="scope">
							<span>{{ scope.row.price | numberToCurrency }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="achievement" align="center" label="PV">
						<template slot-scope="scope">
							<span>{{ scope.row.achievement | numberToCurrency }}</span>
						</template>
					</el-table-column>
					<el-table-column prop="zj" align="center" :label="$t('w_0108')">
						<template slot-scope="scope">
							<span>{{ scope.row.price*scope.row.quantity | numberToCurrency }}
							</span>
						</template>
					</el-table-column>
					<el-table-column prop="zpv" align="center" :label="$t('w_0109')">
						<template slot-scope="scope">
							<span>{{ scope.row.achievement*scope.row.quantity | numberToCurrency }}
							</span>
						</template>
					</el-table-column>
				</el-table>
			</div>

			<span slot="footer" class="dialog-footer">
				<el-button v-print="'#printDom'">{{ $t('w_1019') }}</el-button>
				<el-button type="primary" @click="isPrint = false">{{
          $t("ENU_P_TYPE0")
        }}</el-button>
			</span>
		</el-dialog>
	</div>
</template>

<script>
	import * as api from '@/api/orderComment.js'
	import {
		mapGetters
	} from 'vuex'
	import Pay from '@/components/Pay.vue'
	import * as reg from '@/api/register.js'

	export default {
		components: {
			Pay,
		},
		data() {
			return {
				wlImg: 'https://hzs-in.oss-cn-beijing.aliyuncs.com/test-new/20230701/0d96fb10-fc7d-4d2c-a7c0-af1a70bb6b22.png',
				openDiag: false,
				paramsPost: '',
				isPay: false,
				select: {
					orderType: '',
				},
				orderTypes: [],
				orderStatusList: [],
				creationTime: [],
				// 查询参数
				queryParams: {
					pageNum: 1,
					pageSize: 50,
				},
				total: 0,
				isTab: '',
				orderLists: [],
				waitPayNum: 0,
				payNum: 0,
				isPrint: false, //查看详情
				wlData: [{
					expressName: '',
				}, ],
				isClick: 0,
				isPrint: false, //查看详情
				orderDetailList: {
					itemList: [],
				},
				onlinePay: true
			}
		},
		computed: {
			...mapGetters(['userInfo']),
		},
		watch: {
			isTab(n, o) {
				this.select.orderStatus = n
				this.getDataList()
			},
			$route(to, from) {
				if (to.query.orderCode != from.query.orderCode) {
					this.select.orderCode = to.query.orderCode // 把最新id赋值给定义在data中的id
					this.getDataList()
				}
			},
		},
		mounted() {
			this.getOrderType()
			this.getOrderStatus()
			this.orderNum()
			let allData = decodeURIComponent(window.location.href).split('?')
			if (allData[1].split('&')[1] != undefined) {
				this.select.orderCode = allData[1].split('&')[1].split('=')[1]
			}
			setTimeout(() => {
				this.getDataList()
			})
		},
		methods: {
			// 查看详情
			openpRint(item) {
				console.log(
					'%c [ item ]-503',
					'font-size:13px; background:#b4e874; color:#f8ffb8;',
					item
				)
				this.orderDetailList = item
				this.isPrint = true
			},
			openWl(id) {
				this.openDiag = true
				api.expressList(id).then((res) => {
					if (res.code == 200) {
						this.wlData = res.data
						if (this.wlData.length == 0) {
							this.wlData = [{
								expressName: '',
							}, ]
						}
					}
				})
			},
			cancelPay(val) {
				this.$confirm(this.$t('w_0090'))
					.then((_) => {
						api.cancelOrder(val.orderCode).then((res) => {
							this.getDataList()
							this.$message({
								message: res.msg,
								type: 'success',
							})
						})
					})
					.catch((_) => {})
			},
			goPay(item) {
				let orderItemsParams = []
				item.itemList.forEach((ctem) => {
					if (ctem.waresCode) {
						orderItemsParams.push({
							waresCode: ctem.waresCode,
						})
					}
				})
				let checkObj = {
					specialArea: item.orderType,
					orderItemsParams: orderItemsParams,
				}
				api.payCheck(item.orderCode).then((res) => {
					if (res.code == 200) {
						if (item.orderType == 3 || item.orderType == 22) {
							this.isPay = true
							item.specialArea = item.orderType
							this.onlinePay = 1
							this.paramsPost = item
							// reg.checkPay(checkObj).then((response) => {
							//   if (res.code == 200) {
							//     this.isPay = true
							//     item.specialArea = item.orderType
							//     this.onlinePay = response.data
							//     this.paramsPost = item
							//   }
							// })
						} else {
							this.isPay = true
							item.specialArea = item.orderType
							this.paramsPost = item
						}
					} else {
						this.$message({
							message: res.msg,
							type: 'warning',
						})
					}
				})
			},
			closePay(val) {
				if (val) {
					this.isPay = false
					this.getDataList()
				} else {
					this.$confirm(this.$t('w_0090'))
						.then((_) => {
							this.isPay = false
							// this.$router.replace({
							//   path: "/order/myOrder",
							// });
						})
						.catch((_) => {})
				}
			},
			orderNum() {
				api.orderNum().then((res) => {
					this.waitPayNum = res.data.waitPayNum
					this.payNum = res.data.payNum
				})
			},
			getOrderStatus() {
				api.orderStatus().then((res) => {
					this.orderStatusList = res.data
				})
			},
			getOrderType() {
				api.orderType().then((res) => {
					this.orderTypes = res.data
				})
			},
			changeTime() {
				this.select.creationTimeStart = this.creationTime[0]
				this.select.creationTimeEnd = this.creationTime[1]
			},
			reset() {
				this.creationTime = []
				this.select = {
					orderType: '',
				}
				this.getDataList()
			},
			getDataList() {
				api
					.orderList(Object.assign({}, this.queryParams, this.select))
					.then((res) => {
						this.orderLists = res.rows
						this.total = res.total
					})
			},
		},
	}
</script>

<style lang="scss" scoped>
	.contain {
		padding-top: 20px;

		.kuang {
			background: #ffffff;
			box-shadow: 0px 0px 20px 0px rgba(204, 204, 204, 0.5);
			border-radius: 8px 8px 8px 8px;
			padding: 20px 20px 20px 0;
		}
	}

	.table {
		background: #ffffff;
		box-shadow: 0px 2px 20px 0px rgba(135, 135, 135, 0.3);
		border-radius: 10px 10px 10px 10px;
		margin-top: 40px;
		font-size: 14px;

		.tabList {
			display: flex;
			align-items: center;
			font-weight: 400;
			color: #666666;
			padding: 20px 20px 0 20px;

			.tab_i {
				margin-right: 70px;
				border-bottom: 5px solid #fff;
				padding-bottom: 10px;
				position: relative;
				cursor: pointer;

				.qiu {
					position: absolute;
					width: 20px;
					height: 20px;
					background: #b42b2a;
					top: -10px;
					right: -10px;
					border-radius: 50%;
					font-size: 12px;
					color: #ffffff;
					text-align: center;
					line-height: 20px;
				}
			}

			.tabAct {
				color: #b42b2a;
				border-bottom: 5px solid #b42b2a;
			}
		}
	}

	.searchbox {
		display: flex;
		align-items: center;

		.btn1 {
			background: #cccccc;
			border-radius: 5px 5px 5px 5px;
			color: #ffffff;
		}

		.btn2 {
			border-radius: 5px 5px 5px 5px;
			background: rgba(214, 24, 32, 1);
			color: #ffffff;
		}
	}

	.table_tit {
		background: #eeeeee;
		padding: 30px 0 30px 30px;
		display: flex;
		font-size: 14px;
		font-weight: 600;
		color: #333333;
		align-items: center;
		position: sticky;
		top: 100px;
	}

	.table_tit2 {
		width: 8%;
		text-align: center;
	}

	.t_btm_a {
		display: flex;
		align-items: center;
		padding: 20px 0px 20px 30px;
	}

	.t_btm_l {
		width: 14%;
	}

	.t_btm_r {
		// width: 8%;
		text-align: center;
		display: flex;
		flex: 1;
		align-items: center;
	}

	.table_width {
		text-align: center;

		div {
			flex: 1;
			word-break: break-all;
		}

		// div:nth-child(11) {
		//   width: 8%;
		// }
	}

	.tab_i1 {
		// padding-left: 30px;
		display: flex;
		align-items: center;
		font-size: 11px;
	}

	.tab_i1_1 {
		// display: flex;
		text-align: left;
		flex: 1;
		padding-left: 10px;

		.prot {
			margin-bottom: 5px;
		}

		.prot_i {
			width: 50% !important;
			display: flex;
			align-items: center;
			justify-content: space-between;
			margin-top: 5px;
		}
	}

	.t_top {
		display: flex;
		align-items: center;
		padding: 18px 0 15px 30px;
		border-bottom: 2px solid rgba(0, 0, 0, 0.05);

		span {
			margin-right: 60px;
		}
	}

	.t_btm {
		display: flex;
		align-items: center; // padding: 20px 0px 20px 30px;
		margin-bottom: 10px;

		img {
			width: 64px;
			height: 64px;
			border-radius: 8px 8px 8px 8px;
		}
	}

	.order_i {
		background: #ffffff;
		box-shadow: 0px 4px 20px 0px rgba(204, 204, 204, 0.5);
		border-radius: 10px 10px 10px 10px;
		margin-bottom: 20px;
	}

	.del {
		div:nth-child(2) {
			width: 100%;
		}

		div:nth-child(3) {
			width: 100%;
		}

		div:nth-child(1) {
			width: 100%;
			color: #3499ff;
		}
	}

	.del1 {
		color: #009b58;
		cursor: pointer;
	}

	.del2 {
		color: #d61820;
		cursor: pointer;
	}

	.del3 {
		color: #d61820;
		cursor: pointer;
	}

	.del4 {
		color: #d61820;
		cursor: pointer;
	}

	::v-deep .el-range-editor.el-input__inner {
		width: auto;
	}

	::v-deep .el-select {
		width: 100%;
	}

	.sheng {
		width: 8% !important;
	}

	.delBtn {
		background: #c8161d;
		color: #fff;
		border-color: #c8161d;
	}

	.all_dig {
		display: flex;
		align-items: center;

		.tit {
			font-size: 12px;
			font-family: PingFang SC-Regular, PingFang SC;
			font-weight: 400;
			color: #999999;
			display: inline-block;
			margin-bottom: 30px;
			cursor: pointer;
			margin-right: 20px;
		}

		.act {
			font-size: 16px;
			font-family: PingFang SC-Semibold, PingFang SC;
			font-weight: 600;
			color: #333333;
			display: inline-block;
			border-bottom: 4px solid #b42b2a;
			margin-bottom: 30px;
			cursor: pointer;
			margin-right: 20px;
		}
	}

	.head_t {
		display: flex;
		align-items: center;
		font-size: 12px;
		font-family: PingFang SC-Regular, PingFang SC;
		font-weight: 400;
		color: #666666;
		padding-bottom: 20px;
		margin-bottom: 20px;
		border-bottom: 1px solid #ccc;

		img {
			width: 44px;
			height: 44px;
			margin-right: 10px;
		}
	}

	.wl_tree {
		display: flex;
		margin-bottom: 10px;

		.tree_l {
			width: 30%;
			text-align: right;
		}

		.zhong {
			// flex: 1;
			margin: 0 20px;
			display: flex;
			flex-direction: column;
			align-items: center;

			.qiu {
				width: 10px;
				height: 10px;
				background: #cccccc;
				border-radius: 50%; // margin: 10px 0;
				margin: 3px 0 10px 0;
			}

			.gun {
				background: #ccc;
				width: 1px;
				min-height: 70px;
			}
		}

		.tree_r {
			width: 60%;
		}
	}

	::v-deep .orderCenter .el-dialog__body {
		height: 700px;
		overflow-y: auto;
	}

	.digTit {
		color: #5392f0;
		font-size: 50px;
		text-align: right;
		font-weight: 700;
	}

	.digCent {
		display: flex;
		justify-content: space-between;
		font-size: 18px;
		color: #000;
	}

	.digCent_l {
		flex: 1;
	}

	.digCent_f {
		display: flex;
		align-items: center;
		margin-bottom: 10px;

		:first-child {
			margin-right: 30px;
			font-weight: 500;
		}
	}

	.digCent_r {
		flex: 1;
		text-align: right;
		margin-top: 50px;

		div {
			margin-bottom: 10px;
		}

		:nth-child(1) {
			font-weight: 500;
			font-size: 22px;
		}

		:nth-child(2) {
			font-weight: 500;
			font-size: 22px;
		}
	}
</style>

<style lang='scss'>
	.print {

		.el-table--border:after,
		.el-table--group:after,
		.el-table:before {
			background-color: #000;
		}

		.el-table--border,
		.el-table--group {
			border-color: #000;
		}

		.el-table td,
		.el-table th.is-leaf {
			border-color: #000;
		}

		.el-table--border th,
		.el-table--border th.gutter:last-of-type {
			border-color: #000;
		}

		.el-table--border td,
		.el-table--border th {
			border-color: #000;
		}

		.el-table__row {
			color: #000;
		}
	}

	#printDom {
		table {
			table-layout: auto !important;
		}

		.el-table__header-wrapper .el-table__header {
			width: 100% !important;
		}

		.el-table__body-wrapper .el-table__body {
			width: 100% !important;
		}
	}
</style>