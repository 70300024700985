<template>
  <div class="component-upload-image">
    <el-upload
      :action="uploadImgUrl"
      list-type="picture-card"
      :on-success="handleUploadSuccess"
      :before-upload="handleBeforeUpload"
      :on-error="handleUploadError"
      :disabled="ifdisabled"
      name="file"
      :show-file-list="false"
      :headers="headers"
      class="avatar-uploader"
      style="display: inline-block; vertical-align: top"
    >
      <el-image v-if="!value" :src="value">
        <div slot="error" class="image-slot">
          <i v-if="!backimg" class="el-icon-plus" />
          <img class="backimgBox" v-else :src="backimg" alt="">
        </div>
      </el-image>
      <div v-else class="image mmsk">
        <div v-if="!isVideo">
          <el-image
            :src="value"
            v-if="!backimg" 
            :style="`width:150px;height:150px;`"
            fit="fill"
          />
          <el-image
            :src="value"
            v-else
            :style="`width:300px;height:165px;`"
            fit="fill"
          />
          <div class="mask">
            <div class="actions">
              <span title="预览" @click.stop="dialogVisible = true">
                <i class="el-icon-zoom-in" />
              </span>
              <span v-if="!ifdisabled" title="移除" @click.stop="removeImage">
                <i class="el-icon-delete" />
              </span>
            </div>
          </div>
        </div>
        <div v-else style="padding-bottom: 20px">
          <i class="el-icon-circle-close" @click.stop="removeImage"></i>
          <video
            v-bind:src="value"
            class="video-avatar"
            :autoplay="true"
            controls="controls"
            muted
            loop
          ></video>
        </div>
      </div>
    </el-upload>
    <el-dialog
      :visible.sync="dialogVisible"
      title="预览"
      :close-on-click-modal="false"
      width="800"
      append-to-body
    >
      <img
        :src="value"
        style="display: block; max-width: 100%; margin: 0 auto"
      />
    </el-dialog>
  </div>
</template>
  
  <script>
import { getToken } from "@/util/auth";

export default {
  data() {
    return {
      dialogVisible: false,
      uploadImgUrl: process.env.VUE_APP_BASE_API + "/system/upload", // 上传的图片服务器地址
      headers: {
        Authorization: getToken(),
      },
    };
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    isVideo: {
      type: Boolean,
      default: false,
    },
    ifdisabled: {
      type: Boolean,
      default: false,
    },
    backimg:{
      type:String,
      default:""
    }
  },
  methods: {
    removeImage() {
      this.$emit("input", "");
    },
    handleUploadSuccess(res) {
      if (res.code == 200) {
        this.$emit("input", res.data.url);
      } else {
        this.$message({
          type: "error",
          message: "上传失败",
        });
      }
      this.loading.close();
    },
    handleBeforeUpload(file) {
      this.loading = this.$loading({
        lock: true,
        text: "上传中",
        background: "rgba(0, 0, 0, 0.7)",
      });
      if (this.isVideo) {
        // 文件大小
        var maxSize = 10;
        var size = maxSize * 1024 * 1024;
        if (file.type.split("/")[0] == "video") {
          if (file.size > size) {
            this.$message({
              showClose: true,
              message: `文件过大(不能超过${maxSize}MB)，请重新选择。`,
              type: "error",
            });
            this.loading.close();
            return false;
          }
        } else {
          this.$message({
            showClose: true,
            message: "请选择视频文件",
            type: "error",
          });
          this.loading.close();
          return false;
        }
      }
    },
    handleUploadError() {
      this.$message({
        type: "error",
        message: "上传失败",
      });
      this.loading.close();
    },
  },
  watch: {},
};
</script>
  
  <style scoped lang="scss">
  .backimgBox{
    width: 100%!important;
    height: 100%!important;
  }
.image {
  position: relative;

  .mask {
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    transition: all 0.3s;
  }

  &:hover .mask {
    opacity: 1;
  }
}

.video-avatar {
  width: 300px;
  height: 140px;
  // position: absolute;
  // z-index: 99;
}

.avatar-uploader:hover .el-icon-circle-close {
  visibility: visible;
}
.el-icon-circle-close {
  display: flex;
  float: right;
  margin-right: 6px;
  color: #959bcb;
  font-size: 22px;
  visibility: hidden;
}
::v-deep .el-upload--picture-card {
  width: auto;
  height: auto;
  min-width: 148px !important;
  min-height: 148px !important;
  line-height: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
.mmsk {
  ::v-deep .mask {
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
}
</style>
  