<!--
 * @Descripttion:
 * @version:
 * @Author: kBank
 * @Date: 2023-03-04 09:57:47
-->
<template>
    <div>
        <div class="text-s" @click="thTap">{{$t('MY_TH_1')}}</div>
        <div class="contain">
            <div class="content" >
                <div class="list"  v-if="goodsList.length>0" v-for="(item,index) in goodsList">
                    <div class="banner" :style="{'background-image': 'url('+item.pickCover+')'}">
                      <div class="bottom-s">
                          <div class="goods">
                              {{$t('MY_TH_2')}}：{{item.usableQuantity}}
                          </div>
                          <div class="time-s" v-if="item.receiveTime">
                              {{$t('MY_TH_3')}}：{{item.receiveTime}}
                          </div>
                      </div>
                    </div>
                    <div class="button-s" v-if="item.pickFlag==0" @click="pickSubmitShow(item)"> {{$t('MY_TH_4')}}</div>
                </div>
                <div class="occupied"  v-if="goodsList.length==0">
                    <img  src="@/assets/images/occupied-img.png"/>
                    <div style="margin: 40px 0">
                        {{$t('MY_TH_5')}}
                    </div>
                </div>
            </div>
            <el-dialog :title="$t('MY_TH_1')"
                       :visible.sync="addShow1"
                       center=""
                       width="40%"
                       :close-on-click-modal="false"
                       :before-close="handleCloseBank1">
                <div class="add_pay">
                    <el-table
                        :data="tableData"
                        height="500px"
                        :header-cell-style="{ background: '#EEEEEE' }"
                        :row-class-name="tableRowClassName"
                        :show-header="true"
                    >
                        <el-table-column
                            align="center"
                            prop="pickTypeVal"
                            :label="$t('MY_TH_6')"
                        ></el-table-column>
                        <el-table-column
                            align="center"
                            prop="pickQuantity"
                            :label="$t('MY_TH_7')"
                        ></el-table-column>
                        <el-table-column
                            align="center"
                            prop="pickTime"
                            :label="$t('MY_TH_8')"
                        ></el-table-column>
                        <el-table-column
                            align="center"
                            prop="pickOrder"
                            :label="$t('MY_ORD_1')"
                        >
                            <template slot-scope="scope">
                              <div  @click="orderTap(scope.row.pickOrder)" style="cursor: pointer">
                                  {{scope.row.pickOrder}}
                              </div>
                            </template>

                        </el-table-column>
                    </el-table>
                          <div>
                              <pagination
                                  :total="total1"
                                  :pageSizes="[50]"
                                  :page.sync="queryParams.pageNum"
                                  :limit.sync="queryParams.pageSize"
                                  @pagination="getRecord"
                              />
                          </div>
                </div>
            </el-dialog>
            <el-dialog :title="texts"
                       :visible.sync="addShow"
                       center=""
                       width="25%"
                       :close-on-click-modal="false"
                       :before-close="handleCloseBank">
                <div class="add_pay">
                    <el-form ref="form"
                             :rules="rules"
                             :model="form"
                             label-position="right"
                             label-width="80px">
                       <div class="flex-s">
                           <el-col :span="22">
                           <el-form-item :label="$t('MY_TH_7')"
                                         prop="carryNum1" style="position: relative">
                               <div class="del-icons" @click="del">
                                   <img  src="@/assets/images/del-icon1.png"/>
                               </div>
                               <el-input v-model="form.carryNum"
                                         style="text-align: center"
                                         type="number"
                                         @change="changes"
                                         :placeholder="$t('S_C_70')">

                               </el-input>
                               <div class="add-icons" @click="add">
                                   <img  src="@/assets/images/add-icon.png"/>
                               </div>
                           </el-form-item>
                           </el-col>

                       </div>
                    </el-form>
                </div>
                <div class="surebtn1">

<!--                    <div class="btn hh"-->
<!--                         @click="handleCloseBank">取消</div>-->
                    <div class="btn"
                         @click="pickSubmit()">{{this.$t('MY_WAL_23')}}</div>
                </div>
            </el-dialog>
        </div>
    </div>
</template>
<script>
import * as api from '@/api/activity.js'
import { mapGetters } from 'vuex'
import {getActivityList, pickLog} from "@/api/activity.js";
import {Message} from "element-ui";
export default {
    components: {
    },
    data() {
        return {
            tableData:[],
            mins:10,
            addShow1:false,
            texts: this.$t('MY_TH_4'),
            addShow: false,
            openDiag: false,
            carryGoodsItem: {},
            creationTime: [],
            form: {carryNum:''},
            total: 0,
            total1: 0,
            goodsList: [],
            rules: {
                carryNum: [
                    {required: true, message: this.$t('S_C_70'), trigger: "blur"},
                ],
            },
            // 查询参数
            queryParams: {
                pageNum: 1,
                pageSize: 50,
            },
        }
    },
    computed: {
        ...mapGetters(['userInfo']),
    },
    mounted() {
        this.getDataList()
    },
    methods: {
        orderTap(code){
            this.$router.push({
                path: '/vipCenter',
                query: { id: 1,orderCode:code },
            })
            this.addShow1 = false
        },
        thTap() {
          this.addShow1 = true
            this.getRecord()
        },
        changes(e) {
            if(e%this.form.carryNum1==0 && e<this.form.usableQuantity ){
                this.form.carryNum = e
            }else{
                this.form.carryNum = this.form.carryNum1
            }
            // if(){
            //
            // }
        },
        add() {
            if(this.form.usableQuantity>=this.form.carryNum+this.form.carryNum1){
                this.form.carryNum = this.form.carryNum +this.form.carryNum1
            }
            // this.$forceUpdate();
        },
        del() {
            if(this.form.carryNum>this.form.carryNum1){
                this.form.carryNum = this.form.carryNum - this.form.carryNum1
            }
        },
        tableRowClassName({ row, rowIndex }) {
            if (rowIndex % 2 == 1) {
                return "warning-row";
            } else if (rowIndex % 2 == 0) {
                return "success-row";
            }
            return "";
        },
        getRecord() {
            api
                .pickLog(Object.assign({}, this.queryParams, this.select))
                .then((res) => {
                    console.error(res)
                    this.tableData = res.rows
                    this.total1 = res.total
                })
        },
        getDataList() {
            api
                .getActivityList(Object.assign({}, this.queryParams, this.select))
                .then((res) => {
                    console.error(res)
                    this.goodsList = res.data
                    this.total = res.total
                })
        },
        pickSubmitShow(item) {
          if(item.pickFlag==1){
              this.$router.push({
                  path: '/pickPay',
                  query: { pkId:item.pkId,quantity:item.usableQuantity },
              })
              return
          }
          this.form.carryNum = item.baseQuantity
        this.form.carryNum1 = item.baseQuantity
        this.form.usableQuantity = item.usableQuantity
            this.addShow = true;
          this.carryGoodsItem = item;
            // this.$refs['form'].validate(valid => {
            //     console.error(valid)
            //     if (!valid) {
            //         return false
            //     }else{
            //         console.error("11")
            //     }
            // })
        },
        handleCloseBank1() {
            this.addShow1 = false;
        },
        handleCloseBank() {
            this.addShow = false;
            this.carryGoodsItem = {}
            this.form.carryNum = 0 ;
            this.form.carryNum1 = 0 ;
            this.form.usableQuantity = 0 ;
        },
        pickSubmit() {
            if(this.form.carryNum<1){
                Message({
                    message:this.$t('MY_TH_9') ,
                    type: 'warning',
                })
                return
            }
                if(this.form.carryNum > this.carryGoodsItem.usableQuantity){
                Message({
                    message:this.$t('MY_TH_10'),
                    type: 'warning',
                })
                return
            }
            this.addShow = false;
            this.$router.push({
                path: '/pickPay',
                query: { pkId:this.carryGoodsItem.pkId,quantity:this.form.carryNum },
            })
        },
    },
}
</script>
<style lang="scss" scoped>
.bottom-s{
    position: absolute;
    bottom:0px;
    width: 100%;
    height: 40px;
    background: rgba(0,0,0,0.1);
}
.occupied{
    text-align: center;
    color: #333;
    margin: 90px auto 500px auto;
    font-size: 24px;
    img{
        width: 312px;
        height: 333px;
    }
}
.del-icons{
    cursor: pointer;
    width: 41px;
    height: 40px;
    background: #E3E3E3;
    position: absolute;
    left:0;
    display: flex;
    justify-content: center;
    align-items:center;
    z-index: 1;
    img{
        width: 30px;
        height: 30px;

    }
}
.add-icons{
    cursor: pointer;
    width: 41px;
    height: 40px;
    background: #D5251D;
    position: absolute;
    right:0;
    top: 0;
    display: flex;
    justify-content: center;
    align-items:center;
    z-index: 1;
    img{
        width: 30px;
        height: 30px;

    }
}
.flex-s{
    display: flex;
}
::v-deep .el-dialog__title{
    font-size: 24px;
    color: #333;
    font-weight: bold;
}
::v-deep .el-input__inner{
    text-align: center;
}
.text-s{
    color: #666;
    font-size: 12px;
    text-align: right;
    margin:20px 10px 0 0;
    cursor: pointer;
}
::v-deep .el-dialog__header{
    border-bottom: 1px solid rgba(0,0,0,0.05);;
    padding-bottom: 20px;
}
.surebtn1 {
    display: flex;
    align-items: center;
    justify-content: space-around;
    padding-top: 40px;
    .btn {
        width: 180px;
        height: 50px;
        background: #d5251d;
        border-radius: 6px 6px 6px 6px;
        text-align: center;
        font-size: 14px;
        font-family: PingFang SC-Medium, PingFang SC;
        font-weight: 500;
        color: #ffffff;
        line-height: 50px;
        cursor: pointer;
    }

    .hh {
        background: #cccccc;
    }
}
.button-s{
    width: 124px;
    height: 34px;
    background: #D61820;
    border-radius: 29px 29px 29px 29px;
    opacity: 1;
    line-height: 34px;
    text-align: center;
    color: #fff;
    font-size: 12px;
    margin: 20px auto 40px auto;
    cursor: pointer;
}
.submit{
  width: 86px;
  height: 42px;
  background: #D61820;
  border-radius: 8px 0px 0px 8px;
  right: 0;
  top: 180px;
  position: absolute;
  z-index: 1;
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 42px;
}
.goods-text{
  color: #333;
  font-size: 12px;
  margin: 10px 0 0 20px;
}
.button-n{
  width: 124px;
  height: 34px;
  background: #D61820;
  border-radius: 29px 29px 29px 29px;
  text-align: center;
  line-height: 34px;
  color: #fff;
  font-size: 12px;
  margin: 20px auto 0 auto ;
  cursor: pointer;
}
.lines{
  height: 1px;
  opacity: 0.0451;
  background: #000;
  //border: 1px solid #000000;
}
.title{
  color: #B42B2A;
  font-size: 18px;
  margin-left: 20px;
}
.time{
  color: #999;
  font-size: 12px;
  margin: 10px 10px 10px 20px;
  //margin-left: 20px;
}
.texts{
  //height: 137px;
  padding: 20px 0 30px 0;
  background: #FFFFFF;
  box-shadow: 0px 2px 20px 0px rgba(204,204,204,0.5);
  margin-top: -3px;
  border-radius: 0 0 10px 10px ;
}
.content{
  display: flex;
  flex-wrap: wrap;
}
.button{
  width: 86px;
  height: 42px;
  background: linear-gradient(180deg, #7692FF 0%, #335AF1 100%);
  border-radius: 8px 0px 0px 8px;
  right: 0;
  top: 20px;
  position: absolute;
  z-index: 1;
  text-align: center;
  color: #fff;
  font-size: 12px;
  line-height: 42px;
}
.contain {
  margin-top: 20px;
  background: #fff;
  //height: 470px;
  box-shadow: 0px 2px 20px 0px rgba(135,135,135,0.3);
  //border-radius: 10px 10px 10px 10px;
  padding: 40px 0;
  .list{
    //height: 470px;
    position: relative;
    margin-left: 40px;
    .banner{
      width: 432px;
      height: 253px;
      border-radius: 10px;
      position: relative;
        background-size: 100% 100%;
        .goods{
            position: absolute;
            right: 10px;
            bottom: 10px;
            color: #fff;
            font-size: 16px;
        }
        .time-s{
            position: absolute;
            left: 10px;
            bottom: 10px;
            color: #fff;
            font-size: 16px;
        }
    }
  }
}
</style>