<!--
 * @Descripttion: 
 * @version: 
 * @Author: kBank
 * @Date: 2023-03-03 17:39:11
-->
<template>
  <div class="main">
    <div>
      <!-- 公告弹窗 -->
      <notice-popup
        :userInfo="userInfo"
        :publishLocationIndex="index"
      ></notice-popup>
    </div>
    <sidebarUserInfo></sidebarUserInfo>
    <div class="main_r">
      <topBanner></topBanner>
      <div class="menuList">
        <div
          v-for="item in vipList"
          v-show="item.isShow"
          @click="goMenu(item.id)"
          :class="['menu_i', isMenu == item.id ? 'actMenu' : '']"
          :key="item.id"
        >
          {{ item.text }}
        </div>
      </div>
      <div class="xian"></div>

      <!-- 钱包 -->
      <div v-show="isMenu == 0 && cango">
        <!-- <walletComments></walletComments> -->
        <div class="bartopbox">
          <div class="flexbox">
            <div
              class="levelList_i"
              :class="tabActive == item.id ? 'act' : ''"
              v-for="(item, index) in topFbarList"
              :key="index"
              @click.prevent="handleLink(item.id)"
            >
              {{ item.name }}
            </div>
          </div>
          <div>
            <walletBalance
              v-if="tabActive == 0"
              @handleLink="handleLink"
            ></walletBalance>
            <tradeDetails
              v-if="tabActive == 1"
              :theId="pkAccountId"
            ></tradeDetails>
            <withdrawalDetail v-if="tabActive == 2"></withdrawalDetail>
            <WalletBankCard v-if="tabActive == 3"></WalletBankCard>
          </div>
        </div>
      </div>
      <!-- 订单 -->
      <div v-show="isMenu == 1">
        <orderComments></orderComments>
      </div>
      <!-- 活动专区 -->
      <div v-show="isMenu == 3">
        <activity></activity>
      </div>
      <!-- 提货专区 -->
      <div v-show="isMenu == 4">
        <carryGoods></carryGoods>
      </div>
      <!-- 自助服务 -->
      <div v-show="isMenu == 5">
        <div class="bartopbox">
          <div class="flexbox">
            <div
              class="levelList_i"
              v-for="(item, index) in theselfHeader"
              :key="index"
              @click.prevent="handleLink2(item.id)"
              :class="atabActive == item.id ? 'act' : ''"
            >
              {{ item.name }}
            </div>
          </div>
          <div>
            <!-- 修改地址 -->
            <modifyAddress v-if="atabActive == 1"></modifyAddress>
            <!-- 订单自提 -->
            <selfPickOrders v-if="atabActive == 2"></selfPickOrders>
            <!-- 户籍变更 -->
            <registeredChange v-if="atabActive == 3"></registeredChange>
            <!-- 经销商认证 -->
            <delar v-if="atabActive == 4"></delar>
            <!-- 超级工作室认证 -->
            <superStudio v-if="atabActive == 5"></superStudio>
            <!-- 创客空间认证 -->
            <markerSpace @chnageMenu="handleLink2" v-if="atabActive == 6"></markerSpace>
            <!-- <WalletBankCard v-if="atabActive == 2"></WalletBankCard> -->
            <!-- 月度历史累计 -->
            <monthlyHistory v-if="atabActive == 7"></monthlyHistory>
            <!-- 自助撤单 -->
            <selfCancleOrder v-if="atabActive == 8"></selfCancleOrder>
            <!-- 实名认证 -->
            <existRealName v-if="atabActive == 9&&userInfo.pkCountry == 1"></existRealName>
          </div>
        </div>
      </div>
    </div>
    <el-dialog
      class="pswdialog"
      :title="$t('MY_WAL_21')"
      :visible.sync="pswShow"
      width="30%"
      center
      :close-on-click-modal="false"
      :before-close="handleClose"
    >
      <div class="tit">{{ $t("PER_DA_50") }}</div>
      <el-input v-model="password" type="password"></el-input>
      <div class="btn" @click="surePsw">{{ this.$t("w_0035") }}</div>
    </el-dialog>
  </div>
</template>

<script>
import orderComments from "@/components/orderComments.vue";
import activity from "@/components/activity.vue";
import carryGoods from "@/components/carryGoods.vue";
import sidebarUserInfo from "@/components/sidebarUserInfo.vue";
import topBanner from "@/components/topBanner.vue";
import tradeDetails from "@/components/tradeDetails.vue";
import withdrawalDetail from "@/components/withdrawalDetail.vue";
import walletBalance from "@/components/walletBalance.vue";
import WalletBankCard from "@/components/walletBankCard.vue";
import modifyAddress from "@/components/selfService/modifyAddress.vue";
import registeredChange from "@/components/selfService/registeredChange.vue";
import delar from "@/components/dealer.vue";
import superStudio from "@/components/superStudio.vue";
import markerSpace from "@/components/markerSpace.vue";
import monthlyHistory from "@/components/selfService/monthlyHistory.vue";
import selfPickOrders from "@/components/selfService/selfPickOrders.vue";
import selfCancleOrder from "@/components/selfService/selfCancleOrder.vue";
import existRealName from "@/components/selfService/existRealName.vue";
import noticePopup from "@/components/noticePopup.vue";
import { mapGetters } from "vuex";
import { checkPwd, checkIfShow } from "@/api/wallet.js";
import * as api from "@/api/goods.js";

export default {
  components: {
    noticePopup,
    carryGoods,
    delar,
    activity,
    superStudio,
    markerSpace,
    orderComments,
    sidebarUserInfo,
    topBanner,
    walletBalance,
    withdrawalDetail,
    tradeDetails,
    WalletBankCard,
    registeredChange,
    modifyAddress,
    selfPickOrders,
    monthlyHistory,
    selfCancleOrder,
    existRealName
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  data() {
    return {
      index: 90,
      vipList: [
        {
          text: this.$t("ENU_MENU_901"),
          id: 0,
          name: "wallet",
          isShow: false,
        },
        {
          text: this.$t("ENU_MENU_902"),
          id: 1,
          name: "order",
          isShow: false,
        },
        // {
        //   text: '统计分析',
        //   id: 2,
        // },
        // {
        //   text: this.$t('ENU_MENU_520'),
        //   id: 3,
        // },
        {
          text: this.$t("ENU_MENU_904"),
          id: 4,
          name: "pickGoods",
          isShow: false,
        },
        {
          text: this.$t("ENU_MENU_905"),
          id: 5,
          name: "selfHelp",
          isShow: false,
        },
      ],
      isMenu: 0,
      tabActive: "0",
      atabActive: 1,
      topFbarList: [
        {
          name: this.$t("MY_WAL_1"),
          id: "0",
        },
        {
          name: this.$t("MY_WAL_2"),
          id: "1",
        },
        {
          name: this.$t("MN_F_T_90"),
          id: "2",
        },
        {
          name: this.$t("MY_WAL_3"),
          id: "3",
        },
      ],
      selfServiceList: [
        {
          name: this.$t("ENU_APPROVE_B_231"),
          id: "1",
        },
        {
          name: this.$t("ENU_APPROVE_B_251"),
          id: "2",
        },
        {
          name: this.$t("ENU_APPROVE_B_132"),
          id: "3",
        },
        {
          name: this.$t("ENU_APPROVE_B_143"),
          id: "4",
        },
        {
          name: this.$t("w_1022"),
          id: "5",
        },
        {
          name: this.$t("w_1021"),
          id: "6",
        },
        {
          name: this.$t("MY_CK_38"),
          id: "7",
        },
        {
          name: this.$t("APP_ADD_15"),
          id: "8",
        },
        {
          name: this.$t("MY_WAL_32"),
          id: "9",
        },
      ],
      pkAccountId: "",
      pswShow: false,
      password: "",
      cango: false,
      theselfHeader: [],
    };
  },
  watch: {
    $route(n, o) {
      this.cango = false;
      this.isMenu = n.query.id;
      if (this.isMenu == 0) {
        this.password = "";
        this.pswShow = true;
      }
      if (this.isMenu == 5) {
        this.checkIfShow();
      }
    },
  },
  mounted() {
    this.isMenu = this.$route.query.id;
    this.handleLink2( this.$route.query.atabActiveId||1)
    if(this.userInfo.makerSpaceShow == 1||this.userInfo.makerSpaceShow==undefined) {
      this.selfServiceList= [
        {
          name: this.$t("ENU_APPROVE_B_231"),
          id: "1",
        },
        {
          name: this.$t("ENU_APPROVE_B_251"),
          id: "2",
        },
        {
          name: this.$t("ENU_APPROVE_B_132"),
          id: "3",
        },
        {
          name: this.$t("ENU_APPROVE_B_143"),
          id: "4",
        },
        {
          name: this.$t("w_1022"),
          id: "5",
        },
        {
          name: this.$t("w_1021"),
          id: "6",
        },
        {
          name: this.$t("MY_CK_38"),
          id: "7",
        },
        {
          name: this.$t("APP_ADD_15"),
          id: "8",
        },
        {
          name: this.$t("MY_WAL_32"),
          id: "9",
        },
      ]
    }else{
      this.selfServiceList= [
        {
          name: this.$t("ENU_APPROVE_B_231"),
          id: "1",
        },
        {
          name: this.$t("ENU_APPROVE_B_251"),
          id: "2",
        },
        {
          name: this.$t("ENU_APPROVE_B_132"),
          id: "3",
        },
        {
          name: this.$t("ENU_APPROVE_B_143"),
          id: "4",
        },
        {
          name: this.$t("w_1022"),
          id: "5",
        },
        {
          name: this.$t("w_1033"),
          id: "6",
        },
        {
          name: this.$t("MY_CK_38"),
          id: "7",
        },
        {
          name: this.$t("APP_ADD_15"),
          id: "8",
        },
        {
          name: this.$t("MY_WAL_32"),
          id: "9",
        },
      ]
    }
    if (this.isMenu == 0) {
      this.password = "";
      this.pswShow = true;
    }
    if (this.isMenu == 5) {
      this.checkIfShow();
    }
    this.getMenuList();
    this.ifCodeName();
  },
  methods: {
    ifCodeName() {
      if (this.userInfo.memberCode == "CN68880628") {
        this.topFbarList = [
          {
            name: this.$t("MY_WAL_1"),
            id: "0",
          },
          {
            name: this.$t("MY_WAL_2"),
            id: "1",
          },
          {
            name: this.$t("MY_WAL_3"),
            id: "3",
          },
        ];
      } else {
        this.topFbarList = [
          {
            name: this.$t("MY_WAL_1"),
            id: "0",
          },
          {
            name: this.$t("MY_WAL_2"),
            id: "1",
          },
          {
            name: this.$t("MN_F_T_90"),
            id: "2",
          },
          {
            name: this.$t("MY_WAL_3"),
            id: "3",
          },
        ];
      }
    },
    getMenuList() {
      api.menuList().then((res) => {
        res.data.forEach((item) => {
          this.vipList.forEach((ctem) => {
            if (ctem.name == item.menuKey) {
              ctem.isShow = true;
            }
          });
        });
      });
    },
    //自助服务展示列表
    checkIfShow() {
      checkIfShow().then((res) => {
        this.selfServiceList.forEach((ele) => {
          if (ele.id == 1) {
            ele.ifshow = res.data.existAddress;
          } else if (ele.id == 2) {
            ele.ifshow = res.data.existOrderSelf;
          } else if (ele.id == 3) {
            ele.ifshow = res.data.existDomicile;
          } else if (ele.id == 4) {
            ele.ifshow = res.data.existBusLicense;
          } else if (ele.id == 5) {
            ele.ifshow = res.data.existSuperStudio;
          } else if (ele.id == 6) {
            ele.ifshow = res.data.existMakerSpace;
          } else if (ele.id == 7) {
            ele.ifshow = res.data.existMemberMonth;
          }else if (ele.id == 8) {
            ele.ifshow = res.data.selfRevokeOrder;
          }else if (ele.id == 9) {
            ele.ifshow = res.data.existRealName;
          }
        });
        const theselfHeader = [];
        this.selfServiceList.forEach((element) => {
          if (element.ifshow == 0) {
            theselfHeader.push(element);
          }
        });
        this.theselfHeader = theselfHeader;
        if (this.$route.query.atabActiveId) {
          this.atabActive = this.$route.query.atabActiveId;
        } else {
          this.atabActive = this.theselfHeader[0].id;
        }
      });
    },
    surePsw() {
      checkPwd({ pwd: this.password }).then((res) => {
        if (res.code == 200) {
          this.cango = true;
          this.password = "";
          this.pswShow = false;
        }
      });
    },
    handleClose() {
      this.pswShow = false;
    },
    goMenu(id) {
      if (id == 0) {
        this.password = "";
        this.pswShow = true;
      }
      // if (id == 5) {

      // }
      this.$router.push({
        path: "/vipCenter",
        query: { id: id },
      });
    },
    handleLink(id, pkAccountId) {
      if (pkAccountId) {
        this.tabActive = id;
        this.pkAccountId = pkAccountId;
      } else {
        this.tabActive = id;
      }
    },
    handleLink2(id) {
      this.atabActive = id;
    },
  },
};
</script>

<style lang="scss" scoped>
::v-deep .el-carousel__container {
  height: 500px;
}
::v-deep .pswdialog {
  margin-top: 120px;
}
.tit {
  font-size: 16px;
  font-family: PingFang SC-Regular, PingFang SC;
  font-weight: 400;
  color: #666666;
  text-align: center;
  margin: 10px 0;
}
.btn {
  line-height: 50px;
  width: 180px;
  // height: 50px;
  background: #d5251d;
  border-radius: 6px 6px 6px 6px;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  color: #ffffff;
  margin: 0 auto;
  margin-top: 25px;
  cursor: pointer;
}
.bartopbox {
  margin-top: 20px;
  .flexbox {
    align-items: center;
    display: flex;
  }
  .levelList_i {
    margin-left: 20px;
    position: relative;
    cursor: pointer;
    padding: 0 5px;
    min-width: 110px;
    height: 44px;
    border-radius: 10px;
    border: 1px solid #cccccc;
    font-size: 14px;
    font-family: MicrosoftYaHei;
    text-align: center;
    line-height: 44px;
  }
}
.act {
  color: #ffffff;
  background: rgba(192, 31, 31, 1);
}
.main {
  min-height: 100vh;
  display: flex;
  background: #f8f8f8;
  padding-top: 50px;
  .main_l {
    width: 380px;
    background: #ffffff;
    box-shadow: 5px 5px 20px 0px rgba(233, 233, 233, 0.5);
    border-radius: 10px 10px 10px 10px;
  }
  .main_r {
    flex: 1;
    padding: 0 60px 0 0px;
    .banner {
      width: 1458px;
      height: 253px;
    }
    .menuList {
      display: flex;
      margin-top: 20px;
      padding: 0 24px;
      box-sizing: border-box;
      .menu_i {
        margin-right: 70px;
        line-height: 50px;
        cursor: pointer;
      }
      .actMenu {
        border-bottom: 5px solid #b42b2a;
      }
    }
    .xian {
      height: 5px;
      background: rgba(0, 0, 0, 0.05);
      margin-top: -5px;
    }
  }
}
</style>